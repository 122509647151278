import {connect} from 'react-redux';
import FilteredList from './filtered_list';
import withRouter from '../../../../util/with_router';
import { fetchChannels } from '../../../../actions/channel_actions';
import {
  getJoinedChannels,
  isEducationOrganizationPersona,
  organizationPersona,
} from '../../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities, session } = state;

  return {
    channels: getJoinedChannels(state),
    totalCount: getJoinedChannels(state)?.length,
    channelId: ownProps.match.params.channelId,
    workspaceId: ownProps.match.params.workspaceId,
    location: ownProps.location,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
    currentLocale: session.currentLocale,
    organizationPersona: organizationPersona({ entities }),
  };

};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchChannels: ({ filters }) =>
      dispatch(fetchChannels({ organizationId, workspaceId, filters })),
  };
};

export default withRouter(connect(msp, mdp)(FilteredList));
