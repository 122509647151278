import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import EditTeamMemberPane from '../../../components/pane/EditTeamMemberPane';
import RemoveUserFromTeamAcrossMultipleWorkspacesPane from '../../../components/pane/RemoveUserFromTeamAcrossMultipleWorkspacesPane';
import InviteTeamMembersFilterForm from '../../../components/pane/InviteTeamMembersFilterForm';
import Breadcrumb from '../../../components/common/Breadcrumb';
import { removeTeamMember, getTeamCsv } from '../../../services/team';
import {
  sleep,
  toastifyConfiguration,
} from '../../../components/util/helpers';

const breadcrumbItems = ({ currentLocale, teamId }) => [
  {
    label: 'Access management',
    path: `/#/${currentLocale}/settings/access-management`,
    active: false,
  },
  {
    label: 'Teams',
    path: `/#/${currentLocale}/settings/access-management?access_tab=teams`,
    active: false,
  },
  {
    label: 'Team',
    path: `/#/${currentLocale}/settings/access-management/teams/${teamId}`,
    active: true,
  }
];

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isRemoveMemberPaneOpen: false,
      selectedTeamMember: {},
      team: props.organizationTeam,
      isCsvActionInProgress: false,
    };
    this.handleOnClickEditTeamMember = this.handleOnClickEditTeamMember.bind(this);
    this.handleOnClickRemoveTeamMember = this.handleOnClickRemoveTeamMember.bind(this);
    this.handleOnClickRemoveTeamMemberPane = this.handleOnClickRemoveTeamMemberPane.bind(this);
    this.onEditTeamMemberPaneClose = this.onEditTeamMemberPaneClose.bind(this);
    this.onRemoveTeamMemberPaneClose = this.onRemoveTeamMemberPaneClose.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmRemoval = this.handleConfirmRemoval.bind(this);
  }

  componentDidMount() {
    const { teamId } = this.props;
    if (teamId) {
      this.props.fetchTeam({ teamId });
    }
    Modal.setAppElement(this.el);
  }

  handleOnClickEditTeamMember = user => {
    this.setState({ isPaneOpen: true, selectedTeamMember: user });
  }

  handleOnClickRemoveTeamMemberPane = user => {
    this.setState({ isRemoveMemberPaneOpen: true, selectedTeamMember: user });
  }

  handleConfirmRemoval = async (user, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveTeamMember}
            subtitle="You want to remove this team member?"
            confirmButtonText="Yes, remove it!"
            itemToRemove={user}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveTeamMember = async (user, event) => {
    event.preventDefault();
    const { currentUser, teamId } = this.props;

    const response = await removeTeamMember({
      organization_id: currentUser?.organization_id,
      team_id: teamId,
      team_member_id: user.id,
    });

    if (!(response || {}).error) {
      await this.props.fetchTeam({ teamId });
      toast.success('Team member removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the team member. Please try again.', toastifyConfiguration({}));
    }
  }

  onEditTeamMemberPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  onRemoveTeamMemberPaneClose = () => {
    this.setState({ isRemoveMemberPaneOpen: false });
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser, team } = this.props;
    const { id, name } = team;

    await getTeamCsv({ id: id, team_name: name, organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  render() {
    const {
      currentUser,
      teamId,
      team,
      workspacesAvailable,
      currentLocale,
    } = this.props;

    const {
      isCsvActionInProgress,
      selectedTeamMember,
    } = this.state;

    const teamsUsersCount = team.users?.length;
    const hasMoreThanOneWorkspace = team.workspaces?.length > 1;

    const columns = [{
      id: 'name',
      Header: 'Name',
      sortable: false,
      accessor: u => (
        <div className='username'>
          {`${u.first_name} ${u.last_name}`}
        </div>
      ),
    }, {
      accessor: 'job_title',
      Header: 'Job title',
      sortable: false,
    }, {
      Header: 'Email',
      sortable: false,
      accessor: 'email',
    }, ...hasMoreThanOneWorkspace ? [{
      id: 'workspaces',
      Header: 'Workspaces with team',
      sortable: false,
      width: 300,
      accessor: user => (
        <div className="d-flex row">
          {user.team_workspaces?.map((workspace, index) => (
            <div key={`${workspace.id}-${user.id}-${index}`}>
              {index === 0 && (
                <i
                  className="edit-workspace bi bi-pencil-square me-2"
                  onClick={this.handleOnClickEditTeamMember.bind(this, user)}
                ></i>
              )}
              <span>{workspace.name}</span>
            </div>
          ))}
        </div>
      ),
    }] : [],
    ...hasMoreThanOneWorkspace ? [{
       id: 'remove_workspaces',
       Header: 'Remove',
       sortable: false,
       width: 300,
       accessor: user => (
         <div>
           <span onClick={this.handleOnClickRemoveTeamMemberPane.bind(this, user)} className='remove-cell'>
             <i className="bi bi-pencil-square"></i>
           </span>
         </div>
       )
     }] : [],
     ...!hasMoreThanOneWorkspace ? [{
        id: 'remove',
        Header: 'Remove',
        sortable: false,
        accessor: user => (
          <div>
            <span onClick={this.handleConfirmRemoval.bind(this, user)} className='remove-cell'>
              <i className="bi bi-trash3"></i>
            </span>
          </div>
        )
      }] : [],
    ];

    return (
      <div className="settings organization-team container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header mb-2">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">Team access management</div>
          </div>
        </div>
        <Breadcrumb
          breadcrumbItems={breadcrumbItems({ currentLocale, teamId })}
        />
        <div className="content-container mt-4">
          <div className="row mb-4 resource-metadata">
            <div className="col-md-4 column-width">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="h5">{team.name} users </div>
                    <div className="d-flex">
                      <i className="bi bi-person me-1"></i>
                      <div className="card-count">{teamsUsersCount}</div>
                    </div>
                  </div>
                  <div>
                    Team makeup
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-actions-and-export d-flex justify-content-between">
            <div className="team-member-search">
              <InviteTeamMembersFilterForm
                organizationId={currentUser?.organization_id}
                teamId={teamId}
              />
            </div>
            <div className="ms-2 button-actions">
              {isCsvActionInProgress ? (
                <button className="export export-csv team" type="button" disabled>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  <span>Exporting...</span>
                </button>
              ) : (
                <Button
                  handleClick={() => {
                    this.setState({ isCsvActionInProgress: true });
                    this.handleCsvExport();
                  }}
                  label="Export CSV"
                  classes="export export-csv"
                />
              )}
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={team.users}
              columns={columns}
            />
            <SlidingPane
              className='add-team-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={'Edit Team Member Access'}
              width='60%'
              subtitle=''
              onRequestClose={this.onEditTeamMemberPaneClose}>
                <EditTeamMemberPane
                  teamId={teamId}
                  currentUser={currentUser}
                  onEditTeamMemberPaneClose={this.onEditTeamMemberPaneClose}
                  selectedTeamMember={selectedTeamMember}
                  team={team}
                  fetchTeam={this.props.fetchTeam}
                />
            </SlidingPane>
            <SlidingPane
              className='add-team-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isRemoveMemberPaneOpen }
              title={'Remove Team Member Access'}
              width='60%'
              subtitle=''
              onRequestClose={this.onRemoveTeamMemberPaneClose}>
                <RemoveUserFromTeamAcrossMultipleWorkspacesPane
                  teamId={teamId}
                  currentUser={currentUser}
                  onEditTeamMemberPaneClose={this.onRemoveTeamMemberPaneClose}
                  selectedTeamMember={selectedTeamMember}
                  team={team}
                  fetchTeam={this.props.fetchTeam}
                />
            </SlidingPane>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
