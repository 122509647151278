import {
  ENTERPRISE_ACCOUNT_TYPE,
  FREE_TRIAL_ACCOUNT_TYPE,
  EDUCATION_ACCOUNT_TYPES,
  EDUCATION_PLUS_ACCOUNT_TYPE,
} from '../components/util/helpers';

const ACCOUNT_ADMIN_VALUE = 'account_admin';
const ACCOUNT_CUSTOMER_MANAGER_VALUE = 'account_customer_manager';
const WORKSPACE_MANAGER_VALUE = 'workspace_manager';
const EMAIL_WHITELIST_PLATFORM_ADMIN = '@contactimmed.com';

export const getAllUsernames = ({ entities }) => {
  return Object.values(entities.users).map(user => user.username);
};

export const getChannelMessages = ({ entities }, channelId) => {
  const messages = Object.values(entities.messages).filter(message =>
    message?.channel_id?.toString() === channelId?.toString() &&
    message.message_type !== 'delete'
  );
  return messages.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(a.created_at) - new Date(b.created_at);
  });
};

export const getJoinedChannels = ({ session, entities }) => {
  // TODO: have a check if the user is an admin for the workspace. Thus can
  // can see all channels
  return Object.values(entities.channels);
};

export const getJoinedUsers = ({ entities }, channelId) => {
  const user_ids = Object.values(entities.userChannels)
    .filter(userChannel => userChannel && userChannel.channel_id.toString() === channelId && channelId.toString())
    .map(userChannel => userChannel.user_id);
  return Object.values(entities.users).filter(user => user_ids.includes(user.id));
};

export const getAllPrivateChannels = ({ entities }) => {
  return Object.values(entities.channels).filter(channel => channel.direct_message_channel);
};

export const getAllPublicChannels = ({ entities }) => {
  return Object.values(entities.channels);
};

export const getAllPrivateChannelsOfCurrentUser = (state) => {
  return getJoinedChannels(state).filter(channel => channel.direct_message_channel);
}

export const getMergedMessages = ({ entities }, channelName1, channelName2) => {
  const mergedChannels = Object.values(entities.channels).filter(channel => channel.name === channelName1 || channel.name === channelName2);
  const mergedChannelsIds = mergedChannels.map(channel => channel.id);
  return Object.values(entities.messages).filter(message => message.channel_id.toString() === mergedChannelsIds[0].toString() || message.channel_id.toString() === mergedChannelsIds[1].toString());
};

export const getChannelByName = ({ entities }, channelName) => {
  return Object.values(entities.channels).filter(channel => channel.name === channelName);
};

export const isCurrentUserPlatformAdmin = ({ entities }) => {
  const { currentUser, organization } = entities;

  return isCurrentUserAccountAdmin({ entities }) &&
    organization?.internal &&
    currentUser?.email?.includes(EMAIL_WHITELIST_PLATFORM_ADMIN);
};

export const isCurrentUserFromContactImmed = ({ entities }) => {
  const { currentUser, organization } = entities;

  return isCurrentUserAccountAdmin({ entities }) &&
    currentUser?.email?.includes(EMAIL_WHITELIST_PLATFORM_ADMIN);
};

export const isCurrentUserAccountAdmin = ({ entities }) => {
  const { currentUser } = entities;
  const { roles } = currentUser || {};

  if (!roles) {
    return false;
  }

  const userRoleNames = roles.map(role => role.name);
  return userRoleNames.indexOf(ACCOUNT_ADMIN_VALUE) > -1;
};

export const isCurrentUserCustomerManager = ({ entities }) => {
  const { currentUser } = entities;
  const { roles } = currentUser || {};

  if (!roles) {
    return false;
  }

  const userRoleNames = roles.map(role => role.name);
  return userRoleNames.indexOf(ACCOUNT_CUSTOMER_MANAGER_VALUE) > -1;
};

export const isCurrentUserWorkspaceManager = ({ entities }) => {
  const { currentUser } = entities;
  const { roles } = currentUser || {};

  if (!roles) {
    return false;
  }

  const userRoleNames = roles.map(role => role.name);
  return userRoleNames.indexOf(WORKSPACE_MANAGER_VALUE) > -1;
};

export const orgAccountType = ({ entities }) =>
  entities.organization.account_type;

export const isFreeTrialAccount = ({ entities }) => {
  const accountType = orgAccountType({ entities });
  return FREE_TRIAL_ACCOUNT_TYPE === accountType;
};

export const isEnterpriseAccount = ({ entities }) => {
  const accountType = orgAccountType({ entities });
  return ENTERPRISE_ACCOUNT_TYPE === accountType;
};

export const isEducationAccount = ({ entities }) => {
  const accountType = orgAccountType({ entities });
  return EDUCATION_ACCOUNT_TYPES.includes(accountType);
};

export const isEducationPlusAccount = ({ entities }) => {
  const accountType = orgAccountType({ entities });
  return EDUCATION_PLUS_ACCOUNT_TYPE === accountType;
};

export const canHaveMultipleWorkspaces = ({ entities }) => {
  return featuresEnabled({ entities })?.add_workspaces;
};

export const canAddWorkspaces = ({ entities }) => {
  return featuresEnabled({ entities })?.add_workspaces &&
    isCurrentUserAccountAdmin({ entities });
};

export const isBulkAddUsersEnabled = ({ entities }) => {
  return featuresEnabled({ entities })?.bulk_add_users;
};

export const canViewAuditLogs = ({ entities }) => {
  return featuresEnabled({ entities })?.audit_logs &&
    isCurrentUserAccountAdmin({ entities });
};

export const featuresEnabled = ({ entities }) => {
  const { currentUser } = entities;
  const { featuresEnabled } = currentUser;

  return featuresEnabled;
};

export const organizationPersona = ({ entities }) => {
  const { organization } = entities;
  const { organization_persona } = organization || {};

  return organization_persona;
};

export const isEducationOrganizationPersona = ({ entities }) => {
  const persona = organizationPersona({ entities });

  return persona === 'education';
};

export const isB2bOrganizationPersona = ({ entities }) => {
  const persona = organizationPersona({ entities });

  return persona === 'b2b';
};
