import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import {
  sleep,
  composeValidators,
  isEmailValid,
  statusMapping,
  toastifyConfiguration,
  areArraysEqualSets,
} from '../../components/util/helpers';
import { createTag, updateTag } from '../../services/organization_api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

const HEALTHY_STATUS_VALUE = 'healthy';
const NEUTRAL_STATUS_VALUE = 'neutral';
const AT_RISK_STATUS_VALUE = 'at_risk';

const HEALTHY_STATUS_LABEL = HEALTHY_STATUS_VALUE;
const NEUTRAL_STATUS_LABEL = NEUTRAL_STATUS_VALUE;
const AT_RISK_STATUS_LABEL = 'at risk';

const HEALTH_STATUSES = [
  {
    value: HEALTHY_STATUS_VALUE,
    label: HEALTHY_STATUS_LABEL,
  },
  {
    value: NEUTRAL_STATUS_VALUE,
    label: NEUTRAL_STATUS_LABEL,
  },
  {
    value: AT_RISK_STATUS_VALUE,
    label: AT_RISK_STATUS_LABEL,
  },
];

class AddCustomerTagPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      onAddCustomerTagPaneClose,
      currentUser,
      isEditMode,
      selectedTag,
      handleGetPaginatedCustomerTags,
     } = this.props;
    const {
      name,
      customerCreatedDate,
      customerRenewalDate,
      customerHealthStatus,
    } = values;
    const customerHealthStatusValue = customerHealthStatus?.value;

    const tag = isEditMode
      ? await updateTag({
          organizationId: currentUser?.organization_id,
          tagId: selectedTag.id,
          ...(customerRenewalDate && { customerRenewalDate }),
          ...(customerCreatedDate && { customerCreatedDate }),
          ...(name && { name }),
          ...(customerHealthStatusValue && ({ customerHealthStatus: customerHealthStatusValue })),
        })
      : await createTag({
          organizationId: currentUser?.organization_id,
          customerHealthStatus: customerHealthStatusValue,
          tagType: 'customer',
          customerCreatedDate,
          customerRenewalDate,
          name,
       });
    if (tag && !(tag || {}).error) {
      onAddCustomerTagPaneClose();
      await handleGetPaginatedCustomerTags(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      const action = isEditMode ? 'updated' : 'added';
      toast.success(`Tag, ${tag.name}, ${action} successfully!`, toastifyConfiguration({}));
    } else {
      const action = isEditMode ? 'updating' : 'adding';
      toast.error(`Error ${action} tag ${name}. Please try again.`, toastifyConfiguration({}));
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const { isEditMode, selectedTag } = this.props;
    const { isSubmitting } = this.state

    const customer_health_status = selectedTag?.customer_health_status;

    const initialStatusValue =
      isEditMode &&
      customer_health_status &&
      HEALTH_STATUSES.some(status => status.value === customer_health_status)
      ? { value: customer_health_status, label: statusMapping(customer_health_status) }
      : undefined;

    return (
      <div className="add-workspace-pane">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values)
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <label>Customer name</label>
              <div className="field-container mb-3">
                <Field
                  name="name"
                  validate={required}
                  initialValue={isEditMode ? selectedTag.name : undefined}
                >
                  {({ input, meta }) => (
                    <p className="field name">
                      <input {...input} type="text" placeholder="Customer name" />
                      {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                    </p>
                  )}
                </Field>
              </div>
              <label>Customer health status (optional)</label>
              <div className="field-container">
                <Field
                  name="customerHealthStatus"
                  // initialValue={isEditMode ? selectedTag.customer_health_status : undefined}
                  initialValue={initialStatusValue}
                  component={ReactSelectAdapter}
                  options={HEALTH_STATUSES.map(role => ({
                    value: role.value, label: role.label
                  }))}
                />
              </div>
              <label>Date the company became a customer (optional)</label>
              <div className="field-container">
                <Field
                  name="customerCreatedDate"
                  initialValue={isEditMode ? selectedTag.customer_created_date : undefined}
                  component={ReactDatePickerAdapter}
                />
              </div>
              <label>Customer renewal date (optional)</label>
              <div className="field-container">
                <Field
                  name="customerRenewalDate"
                  initialValue={isEditMode ? selectedTag.customer_renewal_date : undefined}
                  component={ReactDatePickerAdapter}
                />
              </div>
              <div className="submit">
                {isSubmitting ? (
                  <div className="submit-button submitting d-flex align-items-center justify-content-center">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Submitting...</span>
                  </div>
                ) : (
                  <input type="submit" name="commit" value="Submit" disabled={submitting} />
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

AddCustomerTagPane.propTypes = {
  handleGetPaginatedCustomerTags: PropTypes.func,
};

export default AddCustomerTagPane;

// private

const isDateValid = dateStr => {
  return !isNaN(new Date(dateStr));
};

const required = value => value ? undefined : 'Required';

const ReactDatePickerAdapter = ({ input, meta, ...rest }) => (
  <div className="field dropdown date-dropdown">
    <DayPickerInput
      {...input}
      {...rest}
      onDayChange={(date) => {
        if (isDateValid(date)) {
          input.onChange(date);
        } else {
          input.onChange(null);
        }
      }}
    />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
);

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select mb-3">
    <Select {...input} {...rest} />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
);
