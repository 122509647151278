import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';

import { fetchCurrentUser } from '../../../actions/user_actions';

const msp = state => {
  const { entities } = state;

  return {
    currentUser: entities.currentUser,
    emailPreferences: entities.currentUser.email_preferences,
  };
};

const mdp = dispatch => {
  return {
    fetchCurrentUser: ({ organizationId }) => dispatch(
      fetchCurrentUser({ organizationId }),
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
