import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getReactFinalFormSelectValues,
  sleep,
  toastifyConfiguration,
} from '../../components/util/helpers';
import { addUserToTeamAcrossMultipleWorkspaces } from '../../services/team';

toast.configure();

class EditTeamMemberPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const { currentUser, selectedTeamMember, team, onEditTeamMemberPaneClose } = this.props;
    const { workspaces } = values;

    const ids = (workspaces || []).map(ws => ws.value);

    const response = await addUserToTeamAcrossMultipleWorkspaces({
        organization_id: currentUser?.organization_id,
        user_id: selectedTeamMember.id,
        workspace_ids: ids,
        team_name: team.name,
        team_id: team.id,
      });

    if (!(response || {}).error) {
      onEditTeamMemberPaneClose();
      await this.props.fetchTeam({ teamId: team.id });
      toast.success(`User's access updated succesfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error updating team member, ${selectedTeamMember.email}. Please try again.`, toastifyConfiguration({}));
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const { workspacesAvailableForUserAndTeam, selectedTeamMember, team } = this.props;
    const { isSubmitting } = this.state;

    const teamWorkspacesThatUserHasAccessTo = (team?.workspaces || []).filter(
      workspace =>
        (selectedTeamMember?.workspaces || []).some(ws => ws.id === workspace.id)
    );

    const workspaceOptions = teamWorkspacesThatUserHasAccessTo.filter(
      workspace =>
        (selectedTeamMember?.team_workspaces || []).some(ws => ws.id !== workspace.id)
    ).map(workspace => ({
      value: workspace.id, label: workspace.name,
    }));;

    return (
      <div className="add-user-pane">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values);
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="field-container mb-3">
                <label>User email (not editable)</label>
                <Field
                  name="email"
                  initialValue={selectedTeamMember.email}
                >
                  {({ input, meta }) => (
                    <p className="field email">
                      <input {...input} type="text" disabled />
                    </p>
                  )}
                </Field>
              </div>
              <div className="field-container mb-3 pt-4 pb-1 assigned-team-workspaces">
                <label className="bold h5">Team workspaces the user is already a member of</label>
                <ul className="d-flex flex-column">
                  {selectedTeamMember.team_workspaces.map(ws => (
                    <li key={ws.id}>{ws.name}</li>
                  ))}
                </ul>
              </div>
              <div className="field-container mb-3">
                <label>Available workspace(s) for this team <b>AND</b> user</label>
                <Field
                  name="workspaces"
                  component={ReactSingleSelectAdapter}
                  className="select-field workspace-selector"
                  options={workspaceOptions}
                  placeholder="Select team workspace to add user to"
                />
              </div>
              <div className="submit">
                {isSubmitting ? (
                  <div className="submit-button submitting d-flex align-items-center justify-content-center">
                    <span className="spinner-border  spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Submitting...</span>
                  </div>
                ) : (
                  <input type="submit" name="commit" value="Submit" disabled={submitting} />
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

EditTeamMemberPane.propTypes = {
};

export default EditTeamMemberPane;

// private

const ReactSingleSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <Select {...input} {...rest} isMulti />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
);
