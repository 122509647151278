import React from 'react';
import PropTypes from 'prop-types';

import WizardVideoExplainer from './wizard_video_explainer';

const B2B_ACCESS_MANAGEMENT_VIDEO = 'https://www.loom.com/embed/6653c8166de44b6b87d2feec4e9e2151?sid=aa26166c-19fa-4afd-83e9-1feaf27a60a5';
const ED_ACCESS_MANAGEMENT_VIDEO = 'https://www.loom.com/embed/02bae34a1bd94f2a891a89646d385817?sid=c9287f4e-4218-4e58-939d-8b64a69e2d18';

class WizardAccessManagementStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isEducationOrganizationPersona } = this.props;

    const accessManagementVideoUrl = isEducationOrganizationPersona
      ? ED_ACCESS_MANAGEMENT_VIDEO
      : B2B_ACCESS_MANAGEMENT_VIDEO;

    if (isEducationOrganizationPersona) {
      return (
        <>
          <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
            <div className="mb-3">
              Add a user to this workspace and start collaborating quickly within
              your school district.
              Access management lets you easily add users to your district workspaces and
              begin collaborating on requests right away. Users may include teams
              from administrators, teachers, custodial staff, food service, athletics
              staff, IT, maintenance, and much more, each playing a key role in
              well-run schools.
            </div>
            <div className="mb-3">
              Teams are where users can be grouped based on their specific areas of
              responsibility or the parts of the district they oversee. You can
              assign members to teams to streamline collaboration and ensure the
              right people have access to the relevant tools and information.
            </div>
            <div>
              Clever and Google single sign-on are both supported, enabling seamless
              account registrations.
            </div>
          </div>
          <WizardVideoExplainer
            videoEmbedUrl={accessManagementVideoUrl}
          />
        </>
      );
    }

    return (
      <>
        <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
          <div className="mb-3">
            Access management lets you easily add users to your workspace and
            begin collaborating on real customer challenges right away, driving
            both customer success and enterprise value. Users may include teams
            from sales, customer success, engineering, product, leadership, and
            any other team, each playing a key role in your organization's growth.
          </div>
          <div>
            Teams are where users can be grouped based on their specific areas of
            responsibility or the parts of the product they oversee. You can
            assign members to teams to streamline collaboration and ensure the
            right people have access to the relevant tools and information.
          </div>
        </div>
        <WizardVideoExplainer
          videoEmbedUrl={accessManagementVideoUrl}
        />
      </>
    );
  }
}

WizardAccessManagementStepHeader.propTypes = {
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardAccessManagementStepHeader;
