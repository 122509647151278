import React, { Fragment } from "react";
import PropTypes from "prop-types";

import DefaultAvatar from 'images/default_user_avatar.svg';

import Button from '../../../components/common/Button';
import { Form, Field } from 'react-final-form';
import { sleep } from '../../../components/util/helpers';
import { updateUserPassword } from '../../../services/user';
import { updateUser } from '../../../services/organization';


class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditPasswordFormRendered: false,
      fileForUpload: undefined,
      fileToRender: undefined,
      clearLoadedS3Image: false,
      isS3ImageLoadError: false,
      isEditAvatarMode: false,
      isSubmitting: false,
    };
    this.handleShowPasswordForm = this.handleShowPasswordForm.bind(this);
    this.onFileUploadHandler = this.onFileUploadHandler.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleUploadReset = this.handleUploadReset.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.handleNonExistentImage = this.handleNonExistentImage.bind(this);
    this.handleEditAvatar = this.handleEditAvatar.bind(this);
    this.handleUpdateUserMetadata = this.handleUpdateUserMetadata.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
  }

  handleNonExistentImage = () => {
    this.setState({ isS3ImageLoadError: true });
  }

  handleEditAvatar = () => {
    this.setState({ isEditAvatarMode: !this.state.isEditAvatarMode });
  }

  onFileUploadHandler = event => {
    const file = event.target.files[0];

    if (file) {
      this.setState({
        fileForUpload: file,
        fileToRender: URL.createObjectURL(file),
      });
    }
  }

  handleImageUpload = async event => {
    const { fileForUpload } = this.state;
    if (fileForUpload) {
      let that = this;
      const { currentUser } = this.props;
      const { organization_id, id } = currentUser;
      const formData = new FormData()
      formData.append('file', fileForUpload);

      fetch(`/api/organizations/${organization_id}/users/${id}/avatars`, {
        method: 'post',
        body: formData,
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
      })
      .then(response => response.json())
      .then(data => {
        that.setState({
          clearLoadedS3Image: false,
          fileToRender: undefined,
          fileForUpload: undefined,
          isS3ImageLoadError: false,
        });
        that.props.fetchCurrentUser({ organizationId: organization_id });
      })
      .catch(error => {});
    }
  }

  handleUploadReset = () => {
    this.setState({
      fileForUpload: undefined,
      fileToRender: undefined,
      isS3ImageLoadError: false,
    });
    this.refs.file_upload.value = '';
  }

  handleImageRemoval = event => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const { id, organization_id } = currentUser;
    let that = this;
    fetch(`/api/organizations/${organization_id}/users/${id}/avatars`, {
      method: 'delete',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
    })
    .then(response => response.json())
    .then(data => {
      that.props.fetchCurrentUser({ organizationId: organization_id });
      that.setState({
        clearLoadedS3Image: false,
        fileToRender: undefined,
        isS3ImageLoadError: false,
        fileForUpload: undefined,
      });
    })
    .catch(error => {});
  }

  handleShowPasswordForm = () => {
    const { isEditPasswordFormRendered } = this.state;
    this.setState({ isEditPasswordFormRendered: !isEditPasswordFormRendered });
  }

  handleUpdateUserMetadata = async ({
    currentUser,
    displayName,
    jobTitle,
  }) => {
    const response = await updateUser({
      display_name: displayName,
      job_title: jobTitle,
      user_id: currentUser.id,
      organization_id: currentUser?.organization_id,
    })
    if (!(response || {}).error) {
      location.reload();
    }
  }

  handleUpdatePassword = async ({
    currentUser,
    currentPassword,
    password,
    passwordConfirmation,
  }) => {
    const response = await updateUserPassword({
      current_password: currentPassword,
      password: password,
      password_confirmation: passwordConfirmation,
      user_id: currentUser.id,
      organization_id: currentUser?.organization_id,
    })
    if (!(response || {}).error) {
      location.reload();
    }
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      currentPassword,
      displayName,
      jobTitle,
      password,
      passwordConfirmation,
    } = values;
    const { currentUser } = this.props;

    if (currentPassword && password && passwordConfirmation) {
      await this.handleUpdatePassword({
        currentUser,
        currentPassword,
        password,
        passwordConfirmation,
      });
    }

    if (
      (displayName && displayName !== currentUser.display_name) ||
      (jobTitle && jobTitle !== currentUser.job_title)
    ) {
      await this.handleUpdateUserMetadata({
        currentUser,
        displayName,
        jobTitle,
      });
    }
    this.setState({ isSubmitting: false })
  }

  render() {
    let {
      currentUser,
      organizationName,
      isCurrentUserAdmin,
      isSsoEnabled,
    } = this.props;

    const {
      isEditPasswordFormRendered,
      fileForUpload,
      fileToRender,
      clearLoadedS3Image,
      isS3ImageLoadError,
      isEditAvatarMode,
      isSubmitting,
    } = this.state;

    const { id: userId, avatar, display_name, job_title } = currentUser;

    return (
      <div className="settings user-profile container-fluid px-5">
        <div className="content-header">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">My profile</div>
          </div>
        </div>
        <div className="content-container">
          <div className="profile-summary-section me-2">
            <div className="user-picture">
              <div className="avatar-upload">
                <div className="avatar-upload-container">
                  {avatar && avatar.url && !fileForUpload && !fileToRender && (
                    <img
                      className="user-avatar-image"
                      width="180"
                      height="180"
                      onError={this.handleNonExistentImage}
                      src={existentImageSrc({ avatar })}
                      alt="user-avatar"
                    />
                  )}
                  {!(avatar || {}).url && !fileToRender && (
                    <img
                      className="user-avatar-image"
                      src={DefaultAvatar}
                      alt="ContactImmed"
                      height="180"
                      width="180"
                    />
                  )}
                  {fileToRender && (
                    <img
                      className="user-avatar-image"
                      width="180"
                      height="180"
                      src={fileToRender}
                      alt="user-avatar-upload"
                    />
                  )}
                  <i className="bi bi-pencil-fill edit-avatar" onClick={this.handleEditAvatar} />
                </div>
                {isEditAvatarMode && (
                  <div>
                    <div className="avatar-input-container">
                      <label
                        htmlFor="avatarFile" // Associate the label with the file input
                        className="btn btn-secondary avatar-file-uploader"
                      >
                        Upload an Image
                      </label>
                      <input
                        id="avatarFile"
                        ref="file_upload"
                        type="file"
                        className="avatar-file-uploader-hidden"
                        name="file"
                        onChange={this.onFileUploadHandler}
                      />
                      {fileForUpload && (
                        <div className="clear-upload clear-loaded-avatar ms-2" onClick={this.handleUploadReset}>clear</div>
                      )}
                    </div>
                    <div className="button-actions avatar-save-container">
                      <Button
                        handleClick={this.handleImageUpload}
                        label="Save avatar"
                        classes="btn btn-primary add-user save-avatar"
                      />
                    </div>
                    {avatar && avatar.url && (
                      <div className="button-actions avatar-removal-container">
                        <Button
                          handleClick={this.handleImageRemoval}
                          label="Remove Avatar"
                          classes="btn btn-secondary remove-loaded-avatar"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!isEditAvatarMode && (
              <div className="user-avatar-label">Avatar</div>
            )}
          </div>
          <div className="profile-details-section ms-3">
            <div className="form-container">
              {/*
                <div className="profile-item">
                  <div className="sub-header">Full name</div>
                  <div className="profile-item-value">
                    {`${currentUser.first_name || ''} ${currentUser.last_name || ''}`}
                  </div>
                </div>

              */}
              <div className="profile-item row">
                <div className="sub-header col-3">Organization</div>
                <div className="profile-item-value col-9">{organizationName}</div>
              </div>
              <div className="profile-item row">
                <div className="sub-header col-3">Email</div>
                <div className="profile-item-value col-9">{currentUser.email}</div>
              </div>
              <div className="profile-item row">
                <div className="sub-header col-3">Job Title</div>
                <div className="profile-item-value col-9">
                  {currentUser.job_title}
                </div>
              </div>
              <div className="profile-item row">
                <div className="sub-header col-3">Display name</div>
                <div className="profile-item-value col-9">
                  {currentUser.display_name || currentUser.email}
                </div>
              </div>
              {(!isSsoEnabled || isCurrentUserAdmin) && (
                <div className="profile-item row">
                  <div className="sub-header col-3">Password</div>
                  <div
                    className="change-password-link profile-item-value col-9"
                    onClick={this.handleShowPasswordForm}
                  >
                    Change password or display name
                  </div>
                </div>
              )}
              {isEditPasswordFormRendered && (
                <div className="profile-item edit-password-form">
                  <Form
                    onSubmit={(values) => {
                      this.setState({ isSubmitting: true });
                      this.handleOnSubmit(values);
                    }}
                    initialValues={{ displayName: display_name, jobTitle: job_title }}
                    validate={values => {
                      const errors = {};

                      if (
                        values.currentPassword ||
                        values.password ||
                        values.passwordConfirmation
                      ) {
                        if (!values.currentPassword ) {
                          errors.currentPassword = 'Required';
                        }
                        if (!values.password) {
                          errors.password = 'Required';
                        }
                        if (!values.passwordConfirmation) {
                          errors.passwordConfirmation = 'Required';
                        } else if (values.passwordConfirmation !== values.password) {
                          errors.passwordConfirmation = 'Password confirmation must match';
                        }
                      }

                      return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit}>
                        <Field name="jobTitle">
                          {({ input, meta }) => (
                            <div className="field jobTitle">
                              <div className="label">Job Title</div>
                              <input {...input} className="form-control" />
                              {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="displayName">
                          {({ input, meta }) => (
                            <div className="field displayName">
                              <div className="label">Display name</div>
                              <input {...input} className="form-control" />
                              {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="currentPassword">
                          {({ input, meta }) => (
                            <div className="field password">
                              <div className="label">
                                Current password
                                <span>(we need your current password to confirm your changes)</span>
                              </div>
                              <input {...input} className="form-control" type="password" />
                              {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="password">
                          {({ input, meta }) => (
                            <div className="field password">
                              <div className="label">New password</div>
                              <input {...input} className="form-control" type="password" />
                              {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="passwordConfirmation">
                          {({ input, meta }) => (
                            <div className="field password">
                              <div className="label">New password confirmation</div>
                              <input {...input} className="form-control" type="password" />
                              {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <div className="buttons">
                          {isSubmitting ? (
                            <div className="submit-button submitting d-flex align-items-center justify-content-center">
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              <span>Updating...</span>
                            </div>
                          ) : (
                            <button
                              type="submit"
                              className="primary large"
                              disabled={submitting}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </form>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;

const existentImageSrc = ({ avatar }) =>
  avatar && avatar.profile && avatar.profile.url;
