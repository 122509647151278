import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { updateOrganizationByNonPlatformAdmin } from '../../../services/organization_api';
import { toastifyConfiguration } from '../../util/helpers';

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domainApprovalStatus: props.domainApprovalStatus,
    };
    this.handleOnPreferenceUpdate = this.handleOnPreferenceUpdate.bind(this);
  }

  async componentDidMount () {
    const { currentUser } = this.props;
    const { organization_id } = currentUser;
    this.props.fetchCurrentUser({ organizationId: organization_id });
  }


  handleOnPreferenceUpdate = async ({ event, approvalStatus }) => {
    const { currentUser } = this.props;
    const { organization_id } = currentUser;

    const status = approvalStatus ? 'approval_required' : 'automatic';

    this.setState({ domainApprovalStatus: status });

    const response = await updateOrganizationByNonPlatformAdmin({
      organizationId: organization_id,
      domainApprovalStatus: approvalStatus,
    });

    if (response && !response?.error) {
      toast.success(`License settting updated succesfully.`, toastifyConfiguration({}));
      this.props.fetchCurrentUser({ organizationId: organization_id });
    } else {
      toast.error(`Error updating your license setting.`, toastifyConfiguration({}));
    }

  }

  render() {
    const { currentUser } = this.props;

    const { domainApprovalStatus } = this.state;
    const isApprovalRequired = domainApprovalStatus === 'approval_required';

    return (
      <div className="settings user-profile email-preferences container-fluid px-5">
        <div className="content-header">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">License settings</div>
          </div>
        </div>
        <div className="content-container vh-100">
          <div className="d-flex w-100 flex-column email-preferences-items">
            <div className="h4 setting-header border-bottom pb-3 mb-3">
              Invitations and teammates
            </div>
            <div className="setting-option-title bold mb-3">
              Email domain
            </div>

            <div className="setting-options">
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={!isApprovalRequired}
                  onChange={(event) =>
                    this.handleOnPreferenceUpdate({ event, approvalStatus: 0 })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  <span>Allow people with "domain" emails to</span>{' '}
                  <span className="bold">automatically join your organization.</span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={isApprovalRequired}
                  onChange={(event) =>
                    this.handleOnPreferenceUpdate({ event, approvalStatus: 1 })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  <span>Allow people with "domain" emails to</span>{' '}
                  <span className="bold">request approval to join your organization.</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
