import {connect} from 'react-redux';
import FormFields from './form_fields';
import withRouter from '../../../../../util/with_router';
import {
  updateChannel,
  fetchChannelsSummary,
} from '../../../../../actions/channel_actions';
import {
  fetchOrganizationUserMentions,
  fetchOrganizationWorkspaceTags,
} from '../../../../../actions/organization_actions';
import {
  featuresEnabled,
  isEducationOrganizationPersona,
  organizationPersona,
} from '../../../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;

  return {
    currentUser: entities.users[state.session.id],
    channel: entities.channels[ownProps.match.params.channelId],
    teamTags: (entities.organization.tags.team || {}).entries || [],
    customerTags: (entities.organization.tags.customer || {}).entries || [],
    assetTags: (entities.organization.tags.asset || {}).entries || [],
    organizationPersona: organizationPersona({ entities }),
    mentions: entities.organization.mentions,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
    featuresEnabled: featuresEnabled({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    updateChannelMetadataField: ({
      id,
      channel,
    }) => {
      dispatch(updateChannel({ organizationId, id, workspaceId, channel }));
    },
    updateWorkspaceSummaryMetadata: () => {
      dispatch(fetchChannelsSummary({ organizationId , workspaceId }));
    },
    fetchTags: ({ search, tagType }) =>
      dispatch(fetchOrganizationWorkspaceTags({
        organizationId,
        workspaceId,
        search,
        tagType,
      })
    ),
    fetchUserMentions: ({ search }) =>
      dispatch(fetchOrganizationUserMentions({ organizationId, workspaceId, search })),
  };
};

export default withRouter(connect(msp, mdp)(FormFields));
