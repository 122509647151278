import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentLocale, isCurrentUserAccountAdmin } = this.props;

    return (
      <div className="about-page-drawer dashboard">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                <div className="section-paragraph">
                  At a glance, gain a deeper awareness of business trends and
                  customer needs.
                </div>
                <div className="section-paragraph">
                  Understand where your customers encounter the most issues,
                  the popularity of requests, and the monetary impact
                  your efforts can have. Through this dashboard, you can
                  quickly orient yourself and see which customer voices are
                  speaking directly to you and requesting your help.
                </div>
                <div className="section-paragraph">
                  Even if it's not an official roadmap item, you, as a member of
                  your organization, should understand where the market is
                  driving your business and what the customer sentiment is.
                  It'll enable you to work greater impact and not become so silo'd
                  that you don't know what's important for your organization.
                </div>
                <div className="section-paragraph">
                  <b>Discover patterns and insights.</b><br/>
                  Let your teammates' talents
                  shine by providing them with correct customer data. With
                  ContactImmed, everyone in your organization can act as a
                  startup founder by grasping the market and taking initiative.
                </div>
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Sales / customer advocates
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      In your workspace, create Customer Requests communicating the
                      voice of customers.
                      <ol>
                        <li className="list-item first-sublist-item">
                          Describe requests, pain points, and anything that will
                          assist new ARR and support customer retention.
                        </li>
                        <li className="list-item">
                          Let your coworkers know the deal value behind the
                          request, which customers are speaking, and other
                          information that’ll quickly route your voice to the
                          right stakeholders who can get tasks done.
                        </li>
                        <li className="list-item">
                          Whether sales, customer success, someone with a great
                          proposal, or any customer centric stakeholder,
                          communicate the voice through a Customer Request so everyone
                          in your organization can hear it loud and clear.
                        </li>
                      </ol>
                    </li>
                    <li className="list-item last">
                      Invite delivery team members and fellow sales teammates
                      as {isCurrentUserAccountAdmin
                        ? <a href={`/#/${currentLocale}/settings/access-management`}target="_blank">users</a>
                        : 'users'
                      }.
                    </li>
                  </ol>
                </div>
                <div className="underline subsection-header mb-2">Delivery teams</div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Invite coworkers and create teams.
                      <ol>
                        <li className="list-item first-sublist-item">
                          If you're a workspace manager, you can invite coworkers,
                          create teams, and add members to teams based on areas
                          of responsibility.
                        </li>
                      </ol>
                    </li>
                    <li className="list-item">
                      Monitor the dashboard for requests assigned to you and
                      tasks you want to take because it’s important to you. Let
                      your initiative thrive.
                    </li>
                    <li className="list-item last">
                      Participate in "request" discussions.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
