import { paramsToQueryString } from '../services/util/helpers';

export async function getOrganizations({
  search,
  page_size,
  page,
}) {
  const params = { search, page, page_size };
  const url = !paramsToQueryString(params)
    ? `/intranet/organizations.json`
    : `/intranet/organizations.json?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getOrganizationUsers({
  organizationId,
  search,
  page_size,
  page,
}) {
  const params = { search, page, page_size };
  const url = !paramsToQueryString(params)
    ? `/intranet/organizations/${organizationId}/users.json`
    : `/intranet/organizations/${organizationId}/users.json?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getUsers({
  search,
  page_size,
  page,
  organization_id,
}) {
  const params = { search, page, page_size };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organization_id}/users.json`
    : `/organizations/${organization_id}/users.json?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getUsersCsv({
  organization_id,
}) {
  const url = `/organizations/${organization_id}/users/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/users.csv`;
  });
  return;
}

export async function upsertOrganization({
  accountType,
  cleverDistrictId,
  domain,
  name,
  organizationPersona,
  url,
}) {
  const response = await fetch(`/intranet/organizations`, {
    method: 'post',
    body: JSON.stringify({
      name,
      url,
      domain,
      account_type: accountType,
      clever_district_id: cleverDistrictId,
      organization_persona: organizationPersona,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function upsertUser({
  organization_id,
  first_name,
  last_name,
  email,
  roles,
  team,
  job_title,
}) {
  const response = await fetch(`/organizations/${organization_id}/users`, {
    method: 'post',
    body: JSON.stringify({
      organization_id,
      first_name,
      last_name,
      email,
      roles,
      team,
      job_title,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function upsertUsersInBulk({
  organizationId,
  users
}) {
  const response = await fetch(`/organizations/${organizationId}/users/bulk_add_users`, {
    method: 'post',
    body: JSON.stringify({
      organization_id: organizationId,
      users,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateOrganization({
  accountType,
  cleverDistrictId,
  domain,
  disabled,
  id,
  name,
  organizationPersona,
  url,
}) {
  const response = await fetch(`/intranet/organizations/${id}`, {
    method: 'put',
    body: JSON.stringify({
      name,
      url,
      domain,
      disabled,
      account_type: accountType,
      clever_district_id: cleverDistrictId,
      organization_persona: organizationPersona,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateUser({
  organization_id,
  user_id,
  email,
  first_name,
  last_name,
  roles,
  teams,
  display_name,
  job_title,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}`, {
    method: 'put',
    body: JSON.stringify({
      email,
      first_name,
      last_name,
      roles,
      teams,
      display_name,
      job_title,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function assignWorkspaceToUser({
  organization_id,
  user_id,
  workspace_id,
  role,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/assign_workspace`, {
    method: 'put',
    body: JSON.stringify({
      workspace_id,
      role,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateUserWorkspaceRole({
  organization_id,
  user_id,
  workspace_id,
  role,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/update_workspace_role`, {
    method: 'put',
    body: JSON.stringify({
      workspace_id,
      role,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function unassignWorkspaceFromUser({
  organization_id,
  user_id,
  workspace_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}/unassign_workspace`, {
    method: 'put',
    body: JSON.stringify({
      workspace_id,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeOrganization({
  organizationId,
}) {
  const response = await fetch(`/intranet/organizations/${organizationId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeUser({
  organization_id,
  user_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/${user_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeUsers({
  organization_id,
  user_ids,
}) {
  const response = await fetch(`/organizations/${organization_id}/users/delete_all`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: JSON.stringify({
      user_ids,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateAllUsers({
  organization_id,
  user_ids,
  disabled,
}) {
  // temporarily disabled
  return;
  const response = await fetch(`/organizations/${organization_id}/users/update_all`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: JSON.stringify({
      user_ids,
      disabled,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function requestAccessToOrganizationWorkspaces({
  organization_id,
  workspace_access_request_list,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspace_access_requests`, {
    method: 'post',
    body: JSON.stringify({
      organization_id,
      workspace_access_request_list,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function requestAccessToOrganizationTeams({
  organization_id,
  team_access_request_list,
}) {
  const response = await fetch(`/organizations/${organization_id}/team_access_requests`, {
    method: 'post',
    body: JSON.stringify({
      organization_id,
      team_access_request_list,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
