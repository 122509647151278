import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { updateEmailPreference } from '../../../services/email_preferences_api';
import { toastifyConfiguration } from '../../util/helpers';
import { emailPreferencesMapping } from '../../../components/util/common';

toast.configure();

const getSupportedPreferences = emailPreferences => {
  const supportedPreferences = Object.keys(emailPreferencesMapping);
  return emailPreferences.filter(ep =>
    supportedPreferences.indexOf(ep.preference) > -1
  );
}

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailPreferences: props.emailPreferences,
    };
    this.handleOnEmailPreferenceUpdate = this.handleOnEmailPreferenceUpdate.bind(this);
  }


  handleOnEmailPreferenceUpdate = async ({ event, emailPreference }) => {
    const {} = this.state;
    const { currentUser } = this.props;
    const { organization_id } = currentUser;

    this.setState(prevState => {
      // Find the preference to update
      const updatedPreferences = prevState.emailPreferences.map(preference => {
        if (preference.preference === emailPreference.preference) {
          // Toggle the send_email value
          return { ...preference, enabled: !preference.enabled };
        }
        return preference;
      });

      return { emailPreferences: updatedPreferences };
    });

    const checkedValue = !!event?.target?.checked;

    const response = await updateEmailPreference({
      organizationId: organization_id,
      userId: currentUser.id,
      id: emailPreference.id,
      enabled: checkedValue,
    });

    if (response && !response?.error) {
      toast.success(`Email preference updated succesfully.`, toastifyConfiguration({}));
      this.props.fetchCurrentUser({ organizationId: organization_id });
    } else {
      toast.error(`Error updating your perference.`, toastifyConfiguration({}));
    }

  }

  render() {
    const { currentUser } = this.props;


    const { emailPreferences } = this.state;

    return (
      <div className="settings user-profile email-preferences container-fluid px-5">
        <div className="content-header">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">Email preferences</div>
          </div>
        </div>
        <div className="content-container vh-100">
          <div className="d-flex w-100 flex-column email-preferences-items">
            {getSupportedPreferences(emailPreferences).map(ep =>
              <div className="d-flex border-bottom py-3 email-preference-item" key={ep.id}>
                <div className="silent-mode-toggle-label">
                  {emailPreferencesMapping[ep.preference]}
                </div>
                <div className="silent-mode-switch form-check form-switch ms-1 me-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={ep.enabled}
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onChange={(event) =>
                      this.handleOnEmailPreferenceUpdate({ event, emailPreference: ep })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
