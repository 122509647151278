import React from 'react';
import PropTypes from 'prop-types';

class WizardVideoExplainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoLoading: true
    };
    this.handleVideoLoad = this.handleVideoLoad.bind(this);
  }

  handleVideoLoad = () => {
    this.setState({ isVideoLoading: false });
  }

  render() {
    const { videoEmbedUrl } = this.props;

    const { isVideoLoading } = this.state;

    return (
      <div className="wizard-video-explainer loom-video-embed">
        {isVideoLoading && (
          <div className="submit-button submitting d-flex align-items-center justify-content-center">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            <span>Video loading...</span>
          </div>
        )}
        <iframe
          loading="lazy"
          src={videoEmbedUrl}
          frameBorder="0"
          allowFullScreen
          className="loom-video-embed-iframe"
          onLoad={this.handleVideoLoad}
        ></iframe>
      </div>
    );
  }
}

WizardVideoExplainer.propTypes = {};

export default WizardVideoExplainer;
