export async function updateEmailPreference({
  id,
  organizationId,
  userId,
  enabled,
}) {

  const url = `/api/organizations/${organizationId}/users/${userId}/email_preferences/${id}`;

  const response = await fetch(url, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: JSON.stringify({ enabled }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
