import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers/root_reducer';

const configureStore = (preloadedState = {}) => {
  const middlewares = [thunk];

  // Add logger middleware only in development mode
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middlewares)
  );
};

export default configureStore;
