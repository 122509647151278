import React from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import DefaultAvatar from 'images/default_user_avatar.svg';
import Logo from 'images/ci_logo.png';

import ReportAnIssueModal from './report_an_issue_modal';
import { getCookieByName } from '../../util/helpers';
import { logoutCurrentUser } from '../../../services/session_api';

const existentImageSrc = ({ avatar }) =>
  avatar && avatar.profile && avatar.profile.url;

const getDashboardHref = ({ currentLocale, workspaceId }) =>
  workspaceId
    ? `/#/${currentLocale}?workspace_id=${workspaceId}`
    : `/#/${currentLocale}`;

class HorizontalNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOpenReportAnIssueModal = this.handleOpenReportAnIssueModal.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    const { currentUser, workspaces } = this.props;
    const { email_preferences } = currentUser;
    // NOTE: fetch workspaces if not present. In event user refreshes browser
    if (
      !workspaces ||
      (workspaces && workspaces.length === 0)
    ) {
      this.props.fetchWorkspaces();
    }
  }

  handleLogOut = () => {
    const { currentUser } = this.props;
    logoutCurrentUser({ user: currentUser });
  }

  handleOpenReportAnIssueModal = ({ contactType }) => {
    const { currentUser, channels, location, workspaceId } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ReportAnIssueModal
            currentUser={currentUser}
            workspaceId={workspaceId}
            channels={channels}
            onClick={() => null} //this.handleOnFilterChannels}
            location={location}
            contactType={contactType}
            onClose={onClose}
          />
        );
      }
    });
  }

  render() {
    const {
      currentUser,
      workspaceId,
      workspaces,
      isCurrentUserAccountAdmin,
      isCurrentUserPlatformAdmin,
      isCurrentUserWorkspaceManager,
      isEducationOrganizationPersona,
      canHaveMultipleWorkspaces,
      defaultWorkspaceId,
      currentLocale,
      featuresEnabled,
    } = this.props;

    const {
      email,
      first_name,
      last_name,
      username,
      avatar,
      active_invitations_count,
    } = currentUser;

    const hasFullName = first_name && last_name;
    const userInitials = first_name?.charAt(0) + last_name?.charAt(0);

    const canManageUsers =
      isCurrentUserAccountAdmin || isCurrentUserWorkspaceManager;

    const defaultChannelId = (
      workspaces.find(
        workspace => workspace.id === (workspaceId || defaultWorkspaceId)
      ) || {}
    ).first_channel_id;

    const lastVisitedChannelId = getCookieByName('last_visited_channel');
    const channelId = lastVisitedChannelId || defaultChannelId;
    const avatarWidth = avatar && avatar.url ? 64 : 32;

    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary horizontal-navigation py-3 px-3"
      >
        <Container fluid>
          <Navbar.Brand href="/" style={{ width: '5%' }}>
            <img
              src={Logo}
              alt="ContactImmed"
              height="32px"
              width="40px"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between align-items-center">
            <Nav className="me-auto align-items-center">
              <Nav.Link href={getDashboardHref({ currentLocale, workspaceId })}>
                Dashboard
              </Nav.Link>
              {canHaveMultipleWorkspaces && workspaces?.length > 1
                ? (
                  <NavDropdown title="Workspaces" id="collapsible-nav-dropdown">
                    {workspaces.map((workspace, index) => (
                      <NavDropdown.Item
                        href={`/#/${currentLocale}/workspaces/${workspace.id}/requests/${workspace.first_channel_id}`}
                        key={workspace.id}
                      >
                        {workspace.name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link href={`/#/${currentLocale}/workspaces/${defaultWorkspaceId}/requests/${channelId}`}>
                    Workspace
                  </Nav.Link>
                )
              }
              {isCurrentUserPlatformAdmin && featuresEnabled.integrations_enabled && !isEducationOrganizationPersona && (
                <Nav.Link href={`/#/${currentLocale}/settings/integrations`}>Integrations</Nav.Link>
              )}
              {isCurrentUserPlatformAdmin && (
                <Nav.Link href={`/#/${currentLocale}/intranet/organizations`}>Intranet</Nav.Link>
              )}
              {featuresEnabled.feedback_action && (
                <Navbar.Text
                  className="ms-2 report-an-issue-button-container"
                  onClick={() => this.handleOpenReportAnIssueModal({ contactType: 'feedback' })}
                >
                  <div className="report-an-issue-button">
                    <div className="px-2 py-1">Feedback</div>
                  </div>
                </Navbar.Text>
              )}
            </Nav>
            <Nav className="align-items-center">
              {canManageUsers && (
                <NavDropdown
                  title={<i className="bi bi-gear nav-icon"></i>}
                  id="collapsible-nav-dropdown-menu-settings"
                  align="end"
                >
                  {
                    [
                      ...(canManageUsers ? [{ path: `/#/${currentLocale}/settings/access-management`, label: 'Access management' }] : []),
                      ...(canManageUsers && !isEducationOrganizationPersona ? [{ path: `/#/${currentLocale}/settings/customer-tags`, label: 'Customers' }] : []),
                      ...(canManageUsers ? [{ path: `/#/${currentLocale}/settings/requests`, label: 'Requests' }] : []),
                      ...(isCurrentUserAccountAdmin && !isEducationOrganizationPersona && featuresEnabled?.webhooks_enabled ? [{ path: `/#/${currentLocale}/settings/webhooks`, label: 'Webhooks' }] : []),
                      ...(isCurrentUserAccountAdmin && featuresEnabled?.audit_logs  ? [{ path: `/#/${currentLocale}/settings/audit-logs`, label: 'Audit logs' }] : []),
                      ...(isCurrentUserAccountAdmin && featuresEnabled?.pricing_plans ? [{ path: `/#/${currentLocale}/settings/pricing`, label: 'Pricing' }] : []),
                      ...(isCurrentUserAccountAdmin && !isEducationOrganizationPersona && featuresEnabled?.license_settings ? [{ path: `/#/${currentLocale}/settings/license_settings`, label: 'License settings' }] : []),
                    ].map(obj => (
                      <NavDropdown.Item key={obj.label} href={obj.path}>
                        {obj.label}
                      </NavDropdown.Item>
                    ))
                  }
                </NavDropdown>
              )}
              <NavDropdown
                title={<i className="bi bi-question-circle nav-icon"></i>}
                id="collapsible-nav-dropdown-menu-link"
                align="end"
              >
                {
                  [
                    // { path: '#', label: 'Help' },
                    { path: 'https://www.contactimmed.com/terms-and-conditions', label: 'Terms and conditions' },
                    { path: 'https://www.contactimmed.com/privacy-policy', label: 'Privacy policy' }
                  ].map(obj => (
                    <NavDropdown.Item href={obj.path} key={obj.label} target="_blank">
                      {obj.label}
                    </NavDropdown.Item>
                  ))
                }
                <NavDropdown.Item
                  // onClick={() => this.handleOpenReportAnIssueModal({ contactType: 'support' })}
                  href="mailto:support@contactimmed.com"
                >
                  Contact support
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  hasFullName
                  ? (
                    <div className="user-avatar-circle me-2 d-flex align-items-center justify-content-center">
                      <div className="user-initials">{userInitials}</div>
                    </div>
                  ) : <i className="bi bi-person nav-icon"></i>
                }
                id="collapsible-nav-dropdown-menu-avatar"
                align="end"
                className="profile-nav-dropdown"
              >
                <Navbar.Text>
                  <div className="user-info-container py-3 pb-3 mb-3 d-flex flex-column justify-content-center align-items-center">
                    <img
                      className="user-avatar-image me-2"
                      width={avatarWidth}
                      height={avatarWidth}
                      src={avatar && avatar.url ? existentImageSrc({ avatar }) : DefaultAvatar}
                      alt="user-avatar"
                    />
                    <div className="user-info d-flex flex-column align-items-center mt-3">
                      <div className="user-info-name">
                        {first_name ? `${first_name} ${last_name}` : email}
                      </div>
                      {first_name && username !== email && (
                        <div className="user-info-username">
                          {username}
                        </div>
                      )}
                      <a
                        className="btn btn-light go-to-profile rounded-pill mt-2"
                        href={`/#/${currentLocale}/settings/my-profile`}
                        role="button"
                      >
                        View or edit profile
                      </a>
                    </div>
                  </div>
                </Navbar.Text>
                {active_invitations_count ? (
                  <NavDropdown.Item href={`/#/${currentLocale}/settings/my-invitations`}>
                    My invitations
                  </NavDropdown.Item>
                ) : null}
                {featuresEnabled?.two_factor_authentication ? (
                  <NavDropdown.Item href={`/#/${currentLocale}/settings/security`}>
                    Security
                  </NavDropdown.Item>
                ) : null}
                {featuresEnabled?.email_preferences ? (
                  <NavDropdown.Item href={`/#/${currentLocale}/settings/email_preferences`}>
                    Email preferences
                  </NavDropdown.Item>
                ) : null}
                <NavDropdown.Item onClick={this.handleLogOut} href="#" className="nav-log-out pt-3 pb-2 mt-3">
                  Log out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default HorizontalNavigation;
