import React from 'react';
import PropTypes from 'prop-types';

import WizardVideoExplainer from './wizard_video_explainer';

const B2B_REQUEST_CREATION_OVERVIEW_VIDEO = 'https://www.loom.com/embed/f4e3859a42a24811b37c16270046b9d7?sid=f55ac781-a9bd-4ec8-bce6-0a44d63460ae';
const ED_REQUEST_CREATION_OVERVIEW_VIDEO = 'https://www.loom.com/embed/d352ffcf6a2f4b3c975a89fdd557be19?sid=4833b8fe-c5df-4ed5-bd9e-32ce8b486ae7';

class WizardRequestCreationStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isEducationOrganizationPersona } = this.props;

    const requestCreationVideoUrl = isEducationOrganizationPersona
      ? ED_REQUEST_CREATION_OVERVIEW_VIDEO
      : B2B_REQUEST_CREATION_OVERVIEW_VIDEO;

    if (isEducationOrganizationPersona) {
      return (
        <>
          <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
            Requests allow you to log support needs related to school operations,
            instruction, and any service requests that contribute to a highly
            functional school district. Requests can also be directed to
            teams and departments for enhanced visibility and quicker response times.
            For example, a teacher should use ContactImmed to summit a support
            request to maintenance if the air conditioning goes out in a classroom.
          </div>
          <WizardVideoExplainer
            videoEmbedUrl={requestCreationVideoUrl}
          />
        </>
      );
    }

    return (
      <>
        <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
          <div className="mb-3">
            Requests allow you to capture key customer feedback, including
            desires, pain points, and initiatives aimed at driving new ARR
            (Annual Recurring Revenue) and improving customer retention. They can
            also include innovative ideas from your internal team that could shape
            your product or service offerings.
          </div>
          <div>
            All logged requests are seamlessly integrated into the dashboard,
            providing your teams with real-time, actionable insights that are
            always at their fingertips.
          </div>
        </div>
        <WizardVideoExplainer
          videoEmbedUrl={requestCreationVideoUrl}
        />
      </>
    );
  }
}

WizardRequestCreationStepHeader.propTypes = {
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardRequestCreationStepHeader;
