import React from 'react';
import Select from 'react-select';

const Selector = props => <Select aria-label="Select" {...props} />;

const BaseMultiSelector = ({
  onChange,
  onInputChange,
  options,
  selectedOption,
  defaultValue,
  name,
  className,
  placeholder,
  styleOptions = {},
}) => {

  return (
    <Selector
      isMulti
      name={name}
      options={options}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      onInputChange={onInputChange}
      className={className}
      placeholder={placeholder}
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: 'none',
          border: !state.isFocused && 'none',
          '&:hover': {
            backgroundColor: state.isFocused ? 'none' : '#e6e6e6',
          }
        }),
        option: (provided, state) => ({
           ...provided,
           backgroundColor: state.isFocused && 'white',
           color: 'black',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display: !state.isFocused && 'none',
        }),
        clearIndicator: (provided, state) => ({
          ...provided,
          display: !state.isFocused && 'none',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: !state.isFocused && 'none',
        }),
        // multiValue: (provided, state) => ({
        //   ...provided,
        //   backgroundColor: 'none',
        // }),
        // multiValueLabel: (provided, state) => ({
        //   ...provided,
        //   color: state.isFocused ? 'black' : 'blue',
        // }),
        multiValueContainer: (provided, state) => ({
          ...provided,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
        multiValue: provided => ({
          ...provided,
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse' // Reverse the order of elements
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
        multiValueLabel: provided => ({
          ...provided,
          marginRight: '8px', // Add margin to the right of the label
          marginLeft: '0', // Remove margin from the left of the label
        }),
        multiValueRemove: provided => ({
          ...provided,
          marginLeft: '8px', // Add margin to the left of the remove button
          marginRight: '0', // Remove margin from the right of the remove button
        }),
        // input: (provided, state) => ({
        //   ...provided,
        //   display: !state.isFocused && 'none',
        // }),
        ...styleOptions,
      }}
    />
  );
};

export default BaseMultiSelector;
