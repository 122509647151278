import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationUsers } from '../../../actions/organization_actions';
import {
  canAddWorkspaces,
  isBulkAddUsersEnabled,
  isCurrentUserAccountAdmin,
  isCurrentUserWorkspaceManager,
  isEducationOrganizationPersona,
} from '../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;

  return {
    currentUser: entities.users[state.session.id],
    users: entities.organization.users,
    pages: entities.organization.pages || 1,
    usersCount: entities.organization.usersCount || 0,
    adminCount: entities.organization.adminCount || 0,
    workspaceManagerCount: entities.organization.workspaceManagerCount || 0,
    workspacesAvailable: entities.workspaces,
    canAddWorkspaces: canAddWorkspaces({ entities }),
    isBulkAddUsersEnabled: isBulkAddUsersEnabled({ entities }),
    isCurrentUserAccountAdmin: isCurrentUserAccountAdmin({ entities }),
    isCurrentUserWorkspaceManager: isCurrentUserWorkspaceManager({ entities }),
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchUsers: ({ page, pageSize, search, sort }) =>
      dispatch(fetchOrganizationUsers({
        organizationId,
        page,
        pageSize,
        search,
        sort,
      })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
