import { connect } from 'react-redux';

import MessageForm from './message_form';
import withRouter from '../../../../util/with_router';
import { receiveMessage } from '../../../../actions/message_actions';
import { fetchOrganizationUserMentions } from '../../../../actions/organization_actions';
import { isEducationOrganizationPersona } from '../../../../reducers/selectors';

const msp = (state, ownProps) => {
  const { entities } = state;

  return {
    currentUser: entities.currentUser,
    channel: entities.channels[ownProps.match.params.channelId],
    workspaceId: ownProps.match.params.workspaceId,
    mentions: entities.organization.mentions,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    newMessage: (message) => dispatch(receiveMessage(message)),
    fetchUserMentions: ({ search }) =>
      dispatch(fetchOrganizationUserMentions({ organizationId, workspaceId, search })),
  };
};

export default withRouter(connect(msp, mdp)(MessageForm));
