import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardIntroductionStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      canUserCreateUsersOrTeams,
      isEducationOrganizationPersona,
    } = this.props;

    if (isEducationOrganizationPersona) {
      return (
        <div className="text-start activation-modal-home-page">
          <div className="wizard-section-header bold mb-2">
            Welcome to ContactImmed!
          </div>
          <div className="mb-2 wizard-section-header">
            <div className="mb-2">
              We're excited to help your school district get started with our platform. This
              guide will walk you through the key steps to quickly activate your
              account and start using ContactImmed to its full potential.
            </div>
            <div className="mb-2">
              Throughout the guide, we’ll highlight essential features that will
              streamline your request management processes, improve
              collaboration across teams, and enhance communication with all
              district stakeholders. Congratulations to your district for
              centralizing resource requests, custodial and food service
              requests, safety alerts, and inter-district dialogue into a
              cohesive system. By doing so, you’re fostering better
              coordination, faster response times, and a more efficient flow of
              information across departments.
            </div>
            <div className="mb-2">
              Most importantly, with ContactImmed, you can focus on what matters
              most—raising scholars and shaping the next generation of society.
              Let’s get started!
            </div>
          </div>
          <div className="mb-2">
            In this guide, we’ll cover the following topics:
          </div>
          <div className="section-list">
            <ol>
              <li className="bold list-item">
                Dashboard overview
              </li>
              <li className="bold list-item">
                Workspace access
              </li>
              <li className="bold list-item">
                Requests overview
              </li>
              {canUserCreateUsersOrTeams && (
                <li className="list-item">
                  <span className="bold">Access management overview</span>
                    <ul>
                      <li>Adding users</li>
                      <li>Creating teams</li>
                      <li>Assigning users to teams</li>
                    </ul>
                </li>
              )}
              <li className="bold list-item">
                Access to teams
              </li>
            </ol>
          </div>
        </div>
      );
    }

    return (
      <div className="text-start activation-modal-home-page">
        <div className="wizard-section-header bold mb-2">
          Welcome to ContactImmed!
        </div>
        <div className="mb-2 wizard-section-header">
          <div className="mb-2">
            We're excited to help you get started with our platform. This
            guide will walk you through the key steps to quickly activate your
            account and start using ContactImmed to its full potential.
          </div>
          <div className="mb-2">
            As you navigate through the guide, we'll cover essential features
            designed to streamline alignment of teams with the voices of your
            customers and boost collaboration. Cheers to your organization for
            prioritizing customer focus and "activating" deals with efficiency!
          </div>
        </div>
        <div className="mb-2">
          In this guide, we’ll cover the following topics:
        </div>
        <div className="section-list">
          <ol>
            <li className="bold list-item">
              Dashboard overview
            </li>
            <li className="bold list-item">
              Customer requests overview
            </li>
            {canUserCreateUsersOrTeams && (
              <li className="list-item">
                <span className="bold">Access management overview</span>
                  <ul>
                    <li>Adding users</li>
                    <li>Creating teams</li>
                    <li>Assigning users to teams</li>
                  </ul>
              </li>
            )}
            {canUserCreateUsersOrTeams && (
              <li className="list-item">
                <span className="bold">Customer management overview</span>
                <ul>
                  <li>Adding customers</li>
                  <li>Managing customer details (renewal dates, health status, etc...)</li>
                </ul>
              </li>
            )}
            <li className="bold list-item">
              Validating access to teams
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

WizardIntroductionStep.propTypes = {
  canUserCreateUsersOrTeams: PropTypes.bool.isRequired,
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardIntroductionStep;
