import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class WizardCustomerManagementStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
        <div className="mb-3">
          Customer tags play a crucial role in Requests by helping delivery
          teams and other stakeholders quickly identify which customers' voices
          are being heard. These tags are typically added directly within a
          Request's details via the "customers" input field.
        </div>
        <div>
          One of the most powerful features of this view is the ability to track
          customer renewal dates and set health status. By associating renewal
          dates with customer tags, you enable dashboard widgets that show
          which customers are approaching their renewal date. This allows your
          delivery teams to prioritize customer requests, ensuring that
          high-priority accounts are addressed first to maximize renewals.
        </div>
      </div>
    );
  }
}

WizardCustomerManagementStepHeader.propTypes = {};

export default WizardCustomerManagementStepHeader;
