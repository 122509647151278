import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import SingleSelector from '../../common/selectors/single_selector';
import MultiSelector from '../../common/selectors/multi_selector';

import { getTagsForChannels } from '../../../services/channel_api';
import { fetchOrganizationTagsAcrossWorkspaces } from '../../../services/organization_api';

import {
  booleanOptions,
  customerHealthStatusOptions,
  statusOptions,
}  from '../../util/common';
import { queryParamValue, parseQueryString } from '../../util/helpers';

const booleanSelectorOptions = booleanOptions.filter(option => option.value);

class FilterChannelsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValuesSelected: {},
      tags: [],
    };
    this.updateFilterValuesSelected = this.updateFilterValuesSelected.bind(this);
    this.setDefaultFilterSelected = this.setDefaultFilterSelected.bind(this);
    this.handleGetTags = this.handleGetTags.bind(this);
  }

  componentDidMount() {
    this.setDefaultFilterSelected();
    this.handleGetTags();
  }

  setDefaultFilterSelected() {
    const { location } = this.props;
    const { search } = location;

    if (search) {
      const filters = parseQueryString(search);
      const permittedFilters = {
        ...(filters.status && { status: encodeURIComponent(filters.status) }),
        ...(filters.quick_win && { quick_win: encodeURIComponent(filters.quick_win) }),
        ...(filters.broken_window && { broken_window: encodeURIComponent(filters.broken_window) }),
        ...(filters.help_wanted && { help_wanted: encodeURIComponent(filters.help_wanted) }),
        ...(filters.alert && { alert: encodeURIComponent(filters.alert) }),
        // ...(filters.maintenance && { maintenance: encodeURIComponent(filters.maintenance) }),
        // ...(filters.resource_request && { resource_request: encodeURIComponent(filters.resource_request) }),
        ...(filters.safety_issue && { safety_issue: encodeURIComponent(filters.safety_issue) }),
        ...(filters.team && { team: encodeURIComponent(filters.team) }),
        ...(filters.customer && { customer: encodeURIComponent(filters.customer) }),
        ...(filters.asset && { asset: encodeURIComponent(filters.asset) }),
        ...(filters.customer_health_status && { customer_health_status: encodeURIComponent(filters.customer_health_status) }),
        ...(filters.created_by_me && { created_by_me: encodeURIComponent(filters.created_by_me) }),
      };

      this.setState(prevState => ({
        filterValuesSelected: {
          ...prevState.filterValuesSelected,
          ...permittedFilters,
        },
      }));
    }
  }

  updateFilterValuesSelected(field) {
    const { channel } = this.props;

    return (e) => {
      if (e === null) {
        this.setState(prevState => ({
          filterValuesSelected: {
            ...prevState.filterValuesSelected,
            [field]: undefined,
          }
        }));
      } else {
        const value = Array.isArray(e)
          ? e.map(obj => obj.value).join(',')
          : e?.floatValue
          ? e?.floatValue
          : e?.target && e?.target?.value
          ? e.target.value
          : e?.value; // note; a legimate value can be `false`

        this.setState(prevState => ({
          filterValuesSelected: {
            ...prevState.filterValuesSelected,
            [field]: encodeURIComponent(value),
          }
        }));
      }
    }

    return null;
  };

  handleGetTags = async () => {
    event.preventDefault();
    const { currentUser, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = await fetchOrganizationTagsAcrossWorkspaces({
      organizationId,
      workspaceId,
    });

    this.setState({ tags: response?.entries || [] });
  }

  render() {
    const {
      currentUser,
      isEducationOrganizationPersona,
      location,
      onClick,
      onClose,
      organizationPersona,
    } = this.props;

    const { filterValuesSelected, tags } = this.state;

    const { search } = location;

    const statusDefaultValue = defaultFilterValue({ search, key: 'status' });
    const customerHealthStatusDefaultValue = defaultFilterValue({ search, key: 'customer_health_status' });
    const teamDefaultValue = defaultFilterValue({ search, key: 'team' });
    const customerDefaultValue = defaultFilterValue({ search, key: 'customer' });
    const assetDefaultValue = defaultFilterValue({ search, key: 'asset' });

    const customerTagOptions =
      getTagOptionsByType({ tags, type: 'customer' });

    const teamTagOptions =
      getTagOptionsByType({ tags, type: 'team' });

    const assetTagOptions =
      getTagOptionsByType({ tags, type: 'asset' });

    return (
      <div className="filter-channels-modal custom-ui">
        <div className="header mb-4 text-start">
          Filters
        </div>
        <div className="filters-container d-flex row text-align-center mb-5">
          <div className="row mb-4">
            <div className="col-3 text-start">
              <div>Status</div>
              <SingleSelector
                key="statusSelector"
                onChange={this.updateFilterValuesSelected('status')}
                options={statusOptions({ organizationPersona })}
                defaultValue={statusDefaultValue}
                name="statusSelector"
                className="select-field row-selector"
              />
            </div>
            {!isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Quick win</div>
                <SingleSelector
                  key="quickWinSelector"
                  onChange={this.updateFilterValuesSelected('quick_win')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'quick_win' })}
                  name="quickWinSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {!isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Help wanted</div>
                <SingleSelector
                  key="helpWantedSelector"
                  onChange={this.updateFilterValuesSelected('help_wanted')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'help_wanted' })}
                  name="helpWantedSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {!isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Broken window</div>
                <SingleSelector
                  key="brokenWindowSelector"
                  onChange={this.updateFilterValuesSelected('broken_window')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'broken_window' })}
                  name="brokenWindowSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Safety Issue</div>
                <SingleSelector
                  key="safetyIssueSelector"
                  onChange={this.updateFilterValuesSelected('safety_issue')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'safety_issue' })}
                  name="safetyIssueSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Alert</div>
                <SingleSelector
                  key="alertSelector"
                  onChange={this.updateFilterValuesSelected('alert')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'alert' })}
                  name="alertSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {/*isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Resource Request</div>
                <SingleSelector
                  key="resourceRequestSelector"
                  onChange={this.updateFilterValuesSelected('resource_request')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'resource_request' })}
                  name="resourceRequestSelector"
                  className="select-field row-selector"
                />
              </div>
            )*/}
          </div>
          <div className="row mb-4">
            <div className="col-3 text-start">
              <div>Created by me</div>
              <SingleSelector
                key="createdByMeSelector"
                onChange={this.updateFilterValuesSelected('created_by_me')}
                options={booleanSelectorOptions}
                defaultValue={handleBooleanParam({ search, key: 'created_by_me' })}
                name="createdByMeSelector"
                className="select-field row-selector"
              />
            </div>
            {!isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Customer Health Status</div>
                <SingleSelector
                  key="customerHealthStatusSelector"
                  onChange={this.updateFilterValuesSelected('customer_health_status')}
                  options={customerHealthStatusOptions}
                  defaultValue={customerHealthStatusDefaultValue}
                  name="customerHealthStatusSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {!isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Customers</div>
                <MultiSelector
                  key="customersSelector"
                  onChange={this.updateFilterValuesSelected('customer')}
                  options={customerTagOptions}
                  defaultValue={defaultTagFilterValues({ tags: customerDefaultValue })}
                  name="customersSelector"
                  className="select-field row-selector"
                />
              </div>
            )}
            {/*isEducationOrganizationPersona && (
              <div className="col-3 text-start">
                <div>Maintenance</div>
                <SingleSelector
                  key="maintenanceSelector"
                  onChange={this.updateFilterValuesSelected('maintenance')}
                  options={booleanSelectorOptions}
                  defaultValue={handleBooleanParam({ search, key: 'maintenance' })}
                  name="maintenanceSelector"
                  className="select-field row-selector"
                />
              </div>
            )*/}
            <div className="col-3 text-start">
              <div>Teams</div>
              <MultiSelector
                key="teamsSelector"
                onChange={this.updateFilterValuesSelected('team')}
                options={teamTagOptions}
                defaultValue={defaultTagFilterValues({ tags: teamDefaultValue })}
                name="teamsSelector"
                className="select-field row-selector"
              />
            </div>
            <div className="col-3 text-start">
              <div>
                {
                isEducationOrganizationPersona
                  ? 'School and district assets'
                  : 'Features and assets'
                }
              </div>
              <MultiSelector
                key="assetsSelector"
                onChange={this.updateFilterValuesSelected('asset')}
                options={assetTagOptions}
                defaultValue={defaultTagFilterValues({ tags: assetDefaultValue })}
                name="assetsSelector"
                className="select-field row-selector"
              />
            </div>
            {/*
              <div className="col-3 text-start">
                <div>Created by</div>
                <Select
                  isClearable
                  key="createdBySelector"
                  value={selectedUserOption}
                  placeholder={DEFAULT_USER_INPUT_VALUE_LABEL}
                  name="createdBySelector"
                  onChange={this.handleOnChange}
                  onInputChange={this.handleOnUserInputChange}
                  filterOption={() => (true)}
                  formatOptionLabel={formatUserOptionLabel}
                  className="select-field row-selector"
                  options={userOptions(users)} />

                  <MultiSelector
                    key="createdBySelector"
                    onChange={this.updateFilterValuesSelected('createdBy')}
                    options={assetTagOptions}
                    defaultValue={defaultTagFilterValues({ tags: assetDefaultValue })}
                    name="createdBySelector"
                    className="select-field row-selector"
                  />
              </div>
            */}
          </div>
        </div>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 submit-filters-button"
            onClick={() => {
              onClick({ filterValuesSelected });
              onClose();
            }}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

FilterChannelsModal.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default FilterChannelsModal;

const getTagOptionsByType = ({ tags, type }) =>
  tags.filter(
    tag => tag.tag_type === type,
  ).map(tag => ({
    label: tag.name,
    value: tag.name,
  }));

const defaultFilterValue = ({ search, key }) =>
  queryParamValue({ search, key });

const defaultTagFilterValues = ({ tags }) =>
  tags?.split(',')?.map(tag => ({
    label: tag,
    value: tag,
  })) || [];

const handleBooleanParam = ({ search, key }) => {
  const result = queryParamValue({
    search,
    key,
  });

  return result === 'true' ? true : undefined;
}

// const defaultOptions = [{ value: 'All', label: 'All or search users' }];
//
// const userOptions = users =>
//   defaultOptions.concat(users.map(obj =>
//     ({ value: obj.email, label: obj.email, firstName: obj.first_name, lastName: obj.last_name })
//   ));
