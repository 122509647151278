import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class PlanItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      buttonClassName,
      buttonLabel,
      disableButton,
      featuresTagline,
      isButtonDisabled,
      items,
      isPerSeatPricing,
      isPlanActive,
      onClick,
      planCost,
      planCostInterval,
      planDescription,
      planType,
      isEdPlanItem,
    } = this.props;

    return (
      <div className="col py-5 px-3">
        <div className="plan-type d-flex align-items-center mb-2">
          <div className="h3 mb-0">{planType}</div>
          {isPlanActive && (
            <div className="ms-3 badge rounded-pill py-2 px-3">
              Active
            </div>
          )}
        </div>
        <div className="plan-description pb-4">
          {planDescription}
        </div>
        {!isEdPlanItem && (
          <div className="plan-price d-flex align-items-center mb-4">
            <div className="price h2 me-1">{planCost}</div>
            <div className="currency">{planCostInterval}
              {isPerSeatPricing && (<sup>*</sup>)}
            </div>
            {isPerSeatPricing && (
              <div className="seat">Per User</div>
            )}
          </div>
        )}
        <button
          type="button"
          className={buttonClassName || 'select-plan btn btn-primary mt-3 mb-3'}
          onClick={() => isButtonDisabled ? undefined : onClick()}
          disabled={isButtonDisabled}
        >
          {buttonLabel}
       </button>
       <div className="plan-features mt-3">
         <div className="features-tagline">
          {featuresTagline}
         </div>
         {items.map(item => (
           <div key={item} className="feature-item d-flex mb-3">
             <i className="bi bi-check-lg me-1"></i>
             <div className="item">{item}</div>
           </div>
         ))}
      </div>
    </div>
   );
  }
}

PlanItem.propTypes = {};

export default PlanItem;
