import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Field } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Logo from 'images/ci_logo.png';
import WhiteLogo from 'images/ci_white_logo.png';
import CleverLogo from 'images/clever_mark_blue_logo.png';
import EllipseImage from 'images/ellipse.png';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getQueryParamsFromUrl,
  removeQueryParamFromUrl,
  sleep,
  toastifyConfiguration,
} from '../../components/util/helpers';

import {
  loginWithClever,
  loginWithGoogle,
  loginWithPassword,
} from '../../services/login_api';

toast.configure();

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidCredentials: undefined,
      renderOtpView: undefined,
      submittedEmail: undefined,
      submittedPassword: undefined,
      isSubmitting: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleLoginWithGoogle = this.handleLoginWithGoogle.bind(this);
    this.handleLoginWithClever = this.handleLoginWithClever.bind(this);
  }

  componentDidMount() {
    const url = location?.href;
    const params = getQueryParamsFromUrl({ url });
    const isSuccessfulPasswordResetRequest = params?.password_reset_request === 'success';
    const isSuccessfulPasswordReset = params?.password_reset === 'success';
    const isSuccessfulPasswordConfirmationResend = params?.password_confirmation_resend === 'success';
    const isSuccessfulPasswordConfirmation = params?.password_confirmation === 'success';
    const isSuccessfulPasswordSet = params?.password_set === 'success';
    const isGoogleLoginError = params?.google_login === 'error';
    const isCleverLoginError = params?.clever_login === 'error';

    if (isSuccessfulPasswordResetRequest) {
      toast.success(
        <div>
          <strong>Password Reset Request Submitted</strong>
          <p className="mt-2 mb-2">
            Your password reset request was submitted successfully! Please check your email for instructions on how to reset your password.
          </p>
        </div>,
        toastifyConfiguration({ duration: 5000 }),
      );
      removeQueryParamFromUrl({ param: 'password_reset_request' });
    }

    if (isSuccessfulPasswordReset) {
      toast.success(
        <div>
          <strong>Password Reset Successful</strong>
          <p className="mt-2 mb-2">
            Your password has been reset successfully! Please log in with your new password.
          </p>
        </div>,
        toastifyConfiguration({ duration: 5000 }),
      );
      removeQueryParamFromUrl({ param: 'password_reset' });
    }

    if (isSuccessfulPasswordConfirmationResend) {
      toast.success(
        <div>
          <strong>Confirmation Instructions Resent</strong>
          <p className="mt-2 mb-2">
            Your confirmation instructions have been resent successfully! Please check your email for further instructions.
          </p>
        </div>,
        toastifyConfiguration({ duration: 5000 }),
      );
      removeQueryParamFromUrl({ param: 'password_confirmation_resend' });
    }

    if (isSuccessfulPasswordConfirmation) {
      toast.success(
        <div>
          <strong>Confirmation Successful</strong>
          <p className="mt-2 mb-2">
            You have been confirmed successfully! Please proceed to log in.
          </p>
        </div>,
        toastifyConfiguration({ duration: 5000 }),
      );
      removeQueryParamFromUrl({ param: 'password_confirmation' });
    }

    if (isSuccessfulPasswordSet) {
      toast.success(
        <div>
          <strong>Password Set Successfully</strong>
          <p className="mt-2 mb-2">
            Your password has been set successfully! Please log in with your new password.
          </p>
        </div>,
        toastifyConfiguration({ duration: 5000 }),
      );
      removeQueryParamFromUrl({ param: 'password_set' });
    }

    if (isGoogleLoginError) {
      toast.warn(
        <div>
          <strong>Login Error</strong>
          <p className="mt-2 mb-2">Please check with your admin to ensure your organization and your user account is registered with us.</p>
          <p className="mt-2 mb-2">
            <span>If you're not sure, you can</span>{' '}
            <a
              className="login-error contact-us"
              href="https://www.contactimmed.com/contact"
            >
              request access
            </a>{' '}
            <span>by filling out the form on our website.</span>
          </p>
          <p className="mt-2 mb-2">We're here to help if you need any assistance!</p>
        </div>,
        toastifyConfiguration({ duration: 20000 }),
      );
      removeQueryParamFromUrl({ param: 'google_login' });
    }

    if (isCleverLoginError) {
      toast.warn(
        <div>
          <strong>Login Error</strong>
          <p className="mt-2 mb-2">Please check with your administrators to ensure your school district is registered with us.</p>
          <p className="mt-2 mb-2">
            <span>If you're not sure, you can</span>{' '}
            <a
              className="login-error contact-us"
              href="https://www.contactimmed.com/education/contact"
            >
              request access
            </a>{' '}
            <span>by filling out the form on our website.</span>
          </p>
          <p className="mt-2 mb-2">We're here to help if you need any assistance!</p>
        </div>,
        toastifyConfiguration({ duration: 20000 }),
      );
      removeQueryParamFromUrl({ param: 'google_login' });
    }
  }

  onSubmit = async values => {
    // await sleep(300);
    const { email, password, otpAttempt } = values;
    const { submittedEmail, submittedPassword } = this.state;
    const { location } = window;
    const { hash, href } = location;
    const response = await loginWithPassword({
      email: otpAttempt ? submittedEmail : email,
      password: otpAttempt ? submittedPassword : password,
      otpAttempt,
      hash,
    });

    const { error, invalidCredentials, renderOtpView } = response || {};

    if (error || invalidCredentials || renderOtpView) {
      this.setState({
        submittedEmail: response.email,
        submittedPassword: response.password,
        invalidCredentials: response.invalidCredentials,
        renderOtpView: response.renderOtpView,
      });
      this.setState({ isSubmitting: false });
    } else {
      window.location.reload();
      // window.location.href = href;
    }
  }

  handleLoginWithGoogle = () => {
    const { location } = window;
    const { hash } = location;
    loginWithGoogle({ hash });
  }

  handleLoginWithClever = () => {
    const { location } = window;
    const { hash } = location;
    loginWithClever({ hash });
  }

  render () {
    const { location } = window

    const {
      copyRightText,
      currentLocale,
      isForEducation: isEducationUser,
    } = this.props;

    const { isSubmitting, invalidCredentials, renderOtpView } = this.state;

    const url = location?.href;
    const params = getQueryParamsFromUrl({ url });
    const isForEducation = isEducationUser || (params?.for_education === 'true');

    if (renderOtpView) {
      return (
        <section className="vh-100 login-workflows">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-7 d-flex align-items-center justify-content-center session-new">
                <div className="row d-flex text-center align-items-center justify-content-center">
                  <div className="login-workflows-header h1 mb-4">
                    Sign In
                  </div>
                  <div className="login-body login-body-container">
                    <div className="d-flex gap-2 text-center justify-content-center mb-5">
                      <div className="title">Two Factor Authentication</div>
                    </div>
                    <Form
                      onSubmit={(values) => {
                        this.setState({ isSubmitting: true });
                        this.onSubmit(values);
                      }}
                      render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <div className="inner-form-container">
                          <form onSubmit={handleSubmit}>
                            <Field name="otpAttempt" validate={required} autoFocus>
                              {({ input, meta }) => (
                                <div className="form-outline mb-4">
                                  <input {...input} type="text" placeholder="Enter code" className="form-control form-control-md" />
                                  {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                            <div className="submit">
                              {isSubmitting ? (
                                <div className="submit-button w-100 submitting">
                                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                  <span>Continuing...</span>
                                </div>
                              ) : (
                                <input
                                  type="submit"
                                  name="commit"
                                  value="Continue"
                                  className="submit-button w-100"
                                  disabled={submitting}
                                />
                              )}
                            </div>
                          </form>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-5 login-workflows-info-panel">
                <div className="column h-75 d-flex align-items-center text-center justify-content-center">
                  <div className="tagline-container ps-5 pe-5 pt-5 me-5 ms-5">
                    <div className="login-logo-container mb-3">
                      <img src={WhiteLogo} width={100} />
                    </div>
                    {isForEducation && (
                      <>
                        <div className="tagline-header h1 mb-4">
                          Your gateway to every school staff knowing the voice of the
                          customer
                        </div>
                        <div className="tagline-content">
                          Empower staff to easily communicate resource and
                          instructional needs
                        </div>
                      </>
                    )}
                    {!isForEducation && (
                      <>
                        <div className="tagline-header h1 mb-4">
                          Establish a culture of customer empathy and crush sales
                        </div>
                        <div className="tagline-content">
                          Empower sales teams to effectively communicate the voice
                          of the customer to delivery teams.
                        </div>
                        <div className="tagline-content mt-2">
                          Optimize product outcomes. Your ability to hit and exceed
                          revenue targets is only as good as your team members'
                          ability to understand and deliver what customers want.
                        </div>
                      </>
                    )}
                  </div>
                  <div className="login-background-image-container ps-5 ms-5">
                    <img src={EllipseImage} className="img-fluid" alt="Ellipse image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return (
      <section className="vh-100 login-workflows">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-7 d-flex align-items-center justify-content-center session-new">
              <div className="sign-in-container row d-flex text-center align-items-center justify-content-center">
                <div className="login-workflows-header d-flex row h1 mb-4">
                  <div>{isForEducation ? 'Education ' : 'Business '} Sign In</div>
                  <a
                    className="sign-in-path-for-persona mt-2"
                    href={isForEducation
                      ? `/${currentLocale}`
                      : `/${currentLocale}?for_education=true`
                    }
                  >
                    {isForEducation ? 'For Business, Please Go Here ->' : 'For Education, Please Go Here ->'}
                  </a>
                </div>
                <div className="login-body login-body-container">
                  {/*
                    <div className="d-flex gap-2 text-center justify-content-center mb-4">
                      <div className="title">Sign in to your account</div>
                      <a className="signup-link" href={`/${currentLocale}/users/registrations`}>
                        or sign up for free
                      </a>
                    </div>
                  */}
                  <div className="login-omniauth-providers mb-4">
                    <div className="provider d-flex text-center justify-content-center">
                      <button
                        className="provider-signin-button d-flex gap-2 pb-2 pt-2 ps-4 pe-4 text-center align-items-center justify-content-center"
                        onClick={this.handleLoginWithGoogle}
                      >
                        <svg width="16" height="16" viewBox="1 1 14 14" className="me-2">
                          <g fill="none">
                            <path d="M2.629 10.659A5.893 5.893 0 0 1 2 8c0-.956.226-1.858.629-2.659l2.065 1.544a3.487 3.487 0 0 0 0 2.23L2.629 10.66z" fill="#FBBC05"></path>
                            <path d="M2.629 5.341C3.627 3.357 5.713 2 8.139 2c1.563 0 2.959.573 4.047 1.5L10.4 5.245a3.6 3.6 0 0 0-2.26-.79c-1.61 0-2.97 1.015-3.446 2.43L2.629 5.34z" fill="#EA4335"></path>
                            <path d="M2.628 10.657L4.692 9.11c.475 1.417 1.835 2.435 3.448 2.435 1.702 0 2.986-.845 3.293-2.318H8.14V6.91h5.72c.084.355.14.736.14 1.091 0 3.818-2.79 6-5.86 6-2.427 0-4.514-1.358-5.512-3.343z" fill="#34A853"></path>
                            <path d="M12.141 12.506l-1.96-1.483a2.704 2.704 0 0 0 1.252-1.796H8.14V6.91h5.72c.084.355.14.736.14 1.091 0 1.956-.732 3.482-1.859 4.506z" fill="#4285F4"></path>
                          </g>
                        </svg>
                        <div className="provider-button-label ">Continue with Google</div>
                      </button>
                    </div>
                    {isForEducation && (
                      <div className="provider d-flex mt-4 text-center justify-content-center">
                        <button
                          className="provider-signin-button d-flex gap-2 pb-2 pt-2 ps-4 pe-4 text-center align-items-center justify-content-center"
                          onClick={this.handleLoginWithClever}
                        >
                          <img src={CleverLogo} width={16} className="me-2" alt="clever logo" />
                          <div className="provider-button-label ">Continue with Clever</div>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="auth-types-divider mb-4 d-flex column align-items-center justify-content-center">
                    <div className="col-xs-4 divider"><hr /></div>
                    <div className="col-xs-4 ps-2 pe-2">or sign in with</div>
                    <div className="col-xs-4 divider"><hr /></div>
                  </div>
                  <Form
                    onSubmit={(values) => {
                      this.setState({ isSubmitting: true });
                      this.onSubmit(values);
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <div className="inner-form-container">
                        <form onSubmit={handleSubmit}>
                          <Field name="email" validate={required} autoFocus>
                            {({ input, meta }) => (
                              <div className="form-outline mb-4">
                                <input {...input} type="email" placeholder="Enter email" className="form-control form-control-md" autoComplete="email" />
                                {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                              </div>
                            )}
                          </Field>
                          <Field name="password" validate={required}>
                            {({ input, meta }) => (
                              <div className="form-outline mb-3">
                                <input {...input} type="password" placeholder="Password" className="form-control form-control-md" />
                                {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                                {invalidCredentials && (
                                  <span className="field-error">Incorrect email or password</span>
                                )}
                              </div>
                            )}
                          </Field>
                          <div className="shared-registration-links login-workflow-links mb-4">
                            <div>
                              <a href={`/${currentLocale}/users/password/new`}>
                                Forgot your password?
                              </a>
                            </div>
                            <div>
                              <a href={`/${currentLocale}/users/confirmation/new`}>
                                Didn't receive confirmation instructions?
                              </a>
                            </div>
                            <div>
                              <a
                                className="signup-link"
                                href={isForEducation
                                  ? 'https://www.contactimmed.com/education/contact'
                                  : 'https://www.contactimmed.com/contact'
                                }
                              >
                                {isForEducation
                                  ? `District doesn't have an account? Get started for free.`
                                  : `Don't have account? Get started for free.`
                                }

                              </a>
                            </div>
                          </div>
                          <div className="submit">
                            {isSubmitting ? (
                              <div className="submit-button w-100 submitting">
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                <span>Signing in...</span>
                              </div>
                            ) : (
                              <input
                                type="submit"
                                name="commit"
                                value="Sign in"
                                className="submit-button w-100"
                                disabled={submitting}
                              />
                            )}
                          </div>
                          <div className="footer privacy-policy mt-3 d-flex justify-content-between align-items-center">
                            <div className="copyright">
                              <a href="https://contactimmed.com/" target="_blank">
                                <span className="footer-copyright">{copyRightText}</span>
                              </a>
                            </div>
                            <div className="links">
                              <a href="https://www.contactimmed.com/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>
                              <span className="privacy-links-divider ms-1 me-1">•</span>
                              <a href="https://www.contactimmed.com/terms-and-conditions" target="_blank">
                                Terms of Service
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-5 login-workflows-info-panel">
              <div className="column h-75 d-flex align-items-center text-center justify-content-center">
                <div className="tagline-container ps-5 pe-5 pt-5 me-5 ms-5">
                  <div className="login-logo-container mb-3">
                    <img src={WhiteLogo} width={100} />
                  </div>
                  {isForEducation && (
                    <>
                      <div className="tagline-header h1 mb-4">
                        Empowering Every Voice, Uniting School Districts
                      </div>
                      <div className="tagline-content mb-3">
                        ContactImmed is a collaboration tool meant to increase
                        the economy of voices amongst all K-12 district employee
                        and to ensure that no issue, no matter how large or
                        small, goes unresolved due to communication failure.
                      </div>
                      <div className="tagline-content">
                        Foster collaboration, improve response times, and ensure
                        efficient delivery of critical information—across all
                        school district departments and stakeholders.
                      </div>
                    </>
                  )}
                  {!isForEducation && (
                    <>
                      <div className="tagline-header h1 mb-4">
                        Align Every Team with the Voice of Your Customers
                      </div>
                      <div className="tagline-content mb-3">
                        ContactImmed is a collaboration tool meant to serve
                        as your gateway to organization-wide understanding of
                        where the product is failing your customers.
                      </div>
                      <div className="tagline-content">
                        Let's face it. Your ability to hit and exceed revenue
                        targets is only as good as your team's ability to know
                        the customer and act wisely!
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="login-background-image-container ps-5 ms-5">
                <img src={EllipseImage} className="img-fluid" alt="Ellipse image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

LoginForm.propTypes = {};

export default LoginForm;

const required = value => value ? undefined : 'Required';
