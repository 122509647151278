import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';

import configureStore from '../configureStore';
const store = preloadedState => configureStore(preloadedState);

class Root extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { currentLocale } = this.props;
    const location = window.location;

    if (location.pathname === '/' && !location.hash) {
      location.assign(`/#/${currentLocale}`);
    }

    if (location.pathname === `/${currentLocale}` && !location.hash) {
      location.assign(`/#/${currentLocale}`);
    }

    // NOTE: remove this condition if there are location problems.
    // It was added to handle double "currentLocale" situations such as this
    // ex. http://localhost:3000/en#/en/workspaces/<...rest>
    if (location.pathname === `/${currentLocale}` && location.hash) {
      location.assign(`/${location.hash}`);
    }

    if (location.href === `${location.origin}/${currentLocale}#/${currentLocale}`) {
      location.assign(`/#/${currentLocale}`);
    }
  }

  render() {
    const {
      currentUser,
      currentUserOrganization,
      permittedRoles,
      permittedTeamRoles,
      permittedWorkspaceRoles,
      currentLocale,
      lastVisitedChannel,
      lastVisitedWorkspace,
      featuresEnabled,
    } = this.props;
    const userOrgId = currentUser?.organization_id;

    const preloadedState = {
      entities: {
        users: { [currentUser?.id]: currentUser },
        organization: {
          ...currentUserOrganization,
          users: [],
          invitations: {},
          teams: [],
          team: {
            users: [],
          },
          tags: {},
          mentions: [],
          workspaces: [],
          workspaceAccessRequests: [],
          workspace: {
            users: [],
          },
          webhooks: [],
          customerTags: [],
          teamsWithinUserWorkspaces: [],
        },
        currentUser: {
          ...currentUser,
          invitations: {
            entries: [],
            pages: 0,
            invitationsCount: 0,
          },
          featuresEnabled,
        },
        workspaces: [],
      },
      session: { id: currentUser?.id, currentLocale: currentLocale, }
    };

    return (
      <Provider store={store(preloadedState)}>
        <HashRouter basename={`/${currentLocale}`}>
          <App
            {...this.props}
            currentUser={{...currentUser, featuresEnabled}}
            currentUserOrganization={currentUserOrganization}
            permittedRoles={permittedRoles}
            permittedTeamRoles={permittedTeamRoles}
            permittedWorkspaceRoles={permittedWorkspaceRoles}
            currentLocale={currentLocale}
            lastVisitedChannel={lastVisitedChannel}
            lastVisitedWorkspace={lastVisitedWorkspace}
          />
        </HashRouter>
      </Provider>
    );
  }
}

export default Root;
