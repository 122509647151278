import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import AddTeamPane from '../../../components/pane/AddTeamPane';
import {
  getTeams,
  getTeamsCsv,
  removeTeam,
  removeTeams,
} from '../../../services/team';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import TableComponent from '../../../components/common/TableComponent';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedTeam: {},
      search: '',
      teams: [],
      teamsCount: 0,
      isCsvActionInProgress: false,
      selection: [],
    };
    this.handleOnClickAddTeam = this.handleOnClickAddTeam.bind(this);
    this.onAddTeamPaneClose = this.onAddTeamPaneClose.bind(this);
    this.handleOnClickEditTeam = this.handleOnClickEditTeam.bind(this);
    this.handleOnClickRemoveTeam = this.handleOnClickRemoveTeam.bind(this);
    this.handleOnClickRemoveMultipleTeams = this.handleOnClickRemoveMultipleTeams.bind(this);
    this.handleTeamsSearch = this.handleTeamsSearch.bind(this);
    this.handleTeamsFilterFetch = this.handleTeamsFilterFetch.bind(this);
    this.handleGetPaginatedTeams = this.handleGetPaginatedTeams.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    await this.props.fetchTags({ tagType: 'team' });
  }

  debounceSearch = debounce(input => this.handleTeamsFilterFetch(input), 500);

  handleTeamsSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleTeamsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredTeams = await this.props.fetchTeams({ search: input });
    if (filteredTeams) {
      this.setState({ teams: filteredTeams.entries || [], search: input });
    }
  }

  handleGetPaginatedTeams = async (page, pageSize, sorted) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchTeams({
      page: page + 1,
      pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddTeam = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedTeam: {}  });
  }

  onAddTeamPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditTeam = team => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedTeam: team });
  }

  handleConfirmDelete = async (team, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveTeam}
            subtitle="You want to delete this team?"
            itemToRemove={team}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveTeam = async (team, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const response = await removeTeam({
      organization_id: currentUser?.organization_id,
      team_id: team.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedTeams(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Team removed successfully.', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the team. Please try again.', toastifyConfiguration({}));
    }
  }

  handleOnClickRemoveMultipleTeams = async (teamIds, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;

    if (teamIds?.length > 0) {
      const response = await removeTeams({
        organization_id: currentUser?.organization_id,
        team_ids: teamIds,
      });

      if (!(response || {}).error) {
        await this.handleGetPaginatedTeams(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
        toast.success('Team(s) removed successfully.', toastifyConfiguration({}));
      } else {
        toast.error('Error removing the team(s). Please try again.', toastifyConfiguration({}));
      }
    } else {
      return;
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getTeamsCsv({ organization_id: currentUser?.organization_id });
    await sleep(300);
    this.setState({ isCsvActionInProgress: false });
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTdProps = ({ column }) => {
    if (!column) {
      return;
    }

    const { id } = column;
    return {
      style: id === 'tags_list' ? { overflow: 'visible' } : {}
    };
  };

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginatedTeams(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    const {
      currentUser,
      isEducationOrganizationPersona,
      teams,
      teamsCount,
      pages,
      permittedRoles,
      teamTags,
      currentLocale,
      workspacesAvailable,
    } = this.props;

    const teamTagOptions = teamTags.map(tag => ({
      label: tag.name,
      value: tag.name,
    })) || [];

    let {
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedTeam,
    } = this.state;

    const hasMoreThanOneWorkspace = workspacesAvailable?.length > 1;
    const isNotEducationPersona = !isEducationOrganizationPersona;

    const columns = [{
      id: 'name',
      Header: 'Name',
      ...(!hasMoreThanOneWorkspace && { width: 300 }),
      accessor: t => (
        <a
          className='team-name'
          href={`/#/${currentLocale}/settings/access-management/teams/${t.id}`}
        >
          {t.name}
        </a>
      )
    },
    ...hasMoreThanOneWorkspace ? [{
      id: 'workspace',
      Header: 'Workspace',
      sortable: false,
      accessor: team => (
        <div className="d-flex row">
          {team.workspace_name}
        </div>
      ),
    }] : [],
    {
      id: 'user_count',
      Header: 'Member count',
      width: 140,
      sortable: false,
      accessor: t => <span className='user-count'>{(t.user_count)}</span>
    },
    {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).locale(currentLocale).format('YYYY-MM-DD')}</span>
    },
    ...isNotEducationPersona ? [{
      id: 'tags_list',
      Header: 'Tags',
      sortable: false,
      accessor: t => (
        <span className='team-tags'>
          {(t.tags || []).map(tag => tag.name).join(', ')}
        </span>
      )
    }] : [],
    {
      id: 'action',
      Header: 'Action',
      sortable: false,
      width: 120,
      accessor: t => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditTeam.bind(this, t)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="mt-5" ref={ref => this.el = ref}>
        <div className="row mb-4 resource-metadata">
          <div className="col-md-4 column-width">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Teams</div>
                  <div className="d-flex">
                    <i className="bi bi-people me-1"></i>
                    <div className="card-count">{teamsCount}</div>
                  </div>
                </div>
                <div>
                  Group users by responsibility
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 add-resource-column-width">
            <div className="card">
              <div className="card-body d-flex justify-content-center align-items-center">
                <div onClick={this.handleOnClickAddTeam} className="add-resource-action">
                  + Add team
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-actions-and-export d-flex justify-content-between">
          <div className="search">
            <input
              type="text"
              className="form-control search-input"
              onChange={this.handleTeamsSearch}
              placeholder="Search teams"
            />
          </div>
          <div className="ms-2 button-actions">
            {isCsvActionInProgress ? (
              <button className="export export-csv teams" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                <span>Exporting...</span>
              </button>
            ) : (
              <Button
                handleClick={() => {
                  this.setState({ isCsvActionInProgress: true });
                  this.handleCsvExport();
                }}
                label="Export CSV"
                classes="export export-csv"
              />
            )}
          </div>
        </div>
        <div className="container-fluid table-details-section">
          <TableComponent
            columns={columns}
            data={teams}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            deleteConfirmationSubtitle="You want to delete the team(s)?"
            loading={this.state.loading}
            pages={pages}
            handleOnFetchData={this.handleOnFetchData}
            handleSelection={this.handleSelection}
            handleGetTableProps={this.handleGetTableProps}
            handleGetTbodyProps={this.handleGetTbodyProps}
            handleGetTdProps={this.handleGetTdProps}
            handleOnClickRemoveMultipleItems={this.handleOnClickRemoveMultipleTeams}
            multipleDeletionEnabled={true}
          />
          <SlidingPane
            className='add-team-pane'
            overlayClassName='sliding-pane-overlay'
            isOpen={ this.state.isPaneOpen }
            title={isEditMode ? 'Edit Team' : 'Create New Team'}
            width='60%'
            subtitle=''
            onRequestClose={this.onAddTeamPaneClose}>
              <AddTeamPane
                handleGetPaginatedTeams={this.handleGetPaginatedTeams}
                currentUser={currentUser}
                onAddTeamPaneClose={this.onAddTeamPaneClose}
                isEditMode={isEditMode}
                selectedTeam={selectedTeam}
                permittedRoles={permittedRoles || []}
                teamTagOptions={teamTagOptions}
                workspacesAvailable={workspacesAvailable}
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;

const getWorkspaceName = ({ workspacesAvailable, team }) => {
  const workspace = workspacesAvailable.find(workspace => workspace.id === team.workspace_id);
  return workspace ? workspace.name : '';
};
