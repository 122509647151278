import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-modal';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
} from 'recharts';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import { fetchWorkspaceAssetsTaggedCounts } from '../../../services/stats_api';
import { assetChurnRiskDemoData } from '../util/demo_data';

const DIGITAL_ASSET_FAKE_DATA = [
  { tag_name: 'Integrations', count: 343, interactions_count: 0 },
  { tag_name: 'CSV Export', count: 156, interactions_count: 0  },
  { tag_name: 'RBAC', count: 133 },
  { tag_name: 'Site Speed', count: 234 },
  { tag_name: 'History Chart', count: 123 },
  { tag_name: 'Bar Chart', count: 123 },
  { tag_name: 'Your Custom Asset', count: 512 },
  { tag_name: 'Login (SSO)', count: 12 },
];

const domainMax = data => {
  const maxCount = Math.max(...data.map(item => item.count));

  if (maxCount < 10) {
    return 10;
  }

  if (maxCount < 20) {
    return maxCount + 5;
  }

  return maxCount + 20;
};

const barItems = data => {
  if (false) {
    return sortItems(DIGITAL_ASSET_FAKE_DATA);
  }
  return sortItems(data);
}

const sortItems = items =>
  items.sort((a, b) => b.count - a.count);

class AssetChurnRiskAssessmentCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      assetItems: [],
    };
    this.handleBarClick = this.handleBarClick.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    Modal.setAppElement(this.el);
    const { currentUser, defaultChannelId , useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = useDemoData
      ? assetChurnRiskDemoData({ persona: 'tech' })
      : await fetchWorkspaceAssetsTaggedCounts({
        workspaceId,
        organizationId,
      });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, assetItems: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleBarClick(event) {
    const { currentLocale, workspaceId } = this.props;
    const tag_name = event?.tag_name;

    if (!tag_name) {
      return;
    }
    // NOTE: need to use currentLocale here?
    window.open(`${window.location.origin}/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?asset=${tag_name}`);
  }

  render() {
    const {
      currentUser,
      workspaceId,
      defaultChannelId,
      currentLocale,
      handleGeneralUpgradePlanModal,
      isEducationOrganizationPersona,
      noDataMessage,
    } = this.props;

    const WIDGET_TITLE = isEducationOrganizationPersona
      ? 'Priority heatmap of related district and school assets'
      : 'Understand where the product is failing your customers';
      // 'Company assets and features by pain points from user feedback';
    const { isLoading, assetItems } = this.state;
    const isEmptyState = assetItems && !assetItems.length;

    if (isLoading) {
      return (
        <Card className="asset-churn-risk-assessment-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent({ isEducationOrganizationPersona })} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="asset-churn-risk-assessment-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent({ isEducationOrganizationPersona })} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState
                message={noDataMessage}
                currentLocale={currentLocale}
              />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      const data = barItems(assetItems);
      return (
        <Card className="asset-churn-risk-assessment-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent({ isEducationOrganizationPersona })} />
          </Card.Header>
          <Card.Body className="card-content">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                className="container-fluid assets-bar-chart"
                layout="vertical"
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 35,
                }}
              >
                <CartesianGrid strokeDasharray="1 1" vertical={false} opacity={0.5} />
                <XAxis
                  hide
                  axisLine={false}
                  type="number"
                  domain={[0, domainMax(data)]}
                />
                <YAxis
                  yAxisId={0}
                  dataKey="tag_name"
                  type="category"
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  tickFormatter={tickFormatter}
                  orientation="left"
                />
                <YAxis
                  orientation="right"
                  yAxisId={1}
                  dataKey="count"
                  type="category"
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  tickFormatter={tickFormatter}
                />
                <Tooltip />
                <Legend layout="horizontal" verticalAlign="top" align="right" />
                <Bar
                  dataKey="count"
                  yAxisId={0}
                  barSize={40}
                  fill="#e90101"
                  // fill="#cd3d64"
                  onClick={this.handleBarClick}
                  name="count of assets involved"
                >
                </Bar>
                {/* <Bar dataKey="interactions_count" fill="#82ca9d" /> */}
              </BarChart>
            </ResponsiveContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

AssetChurnRiskAssessmentCard.propTypes = {};

AssetChurnRiskAssessmentCard.defaultProps = {
 noDataMessage: 'No data available for product failure insights'
};

export default AssetChurnRiskAssessmentCard;

const tickFormatter = (value, index) => {
   return value?.toLocaleString();
};

const titleContent = ({ isEducationOrganizationPersona }) => isEducationOrganizationPersona
  ? (
    <div className="tooltip-content">
      <p className="tooltip-paragraph header">
        "Related school and district assets" are the resources that a school or
        district owns or controls to support its educational goals.
      </p>
      <p className="tooltip-paragraph">
        These assets can be physical, like buildings and equipment, or
        intangible, like software licenses. For example, "buildings", "equipment",
        "vehicles", "windows", "furniture", "supplies", and much more can
        be considered related school and district assets.
      </p>
      <p className="tooltip-paragraph">
        By using the <b>Related school and district assets</b> field when
        managing a request, you aid internal visibility by generating a heatmap
        of activity, requests, and issues that are most common. The greater the
        visibility, the more likely the matter is to get resolved and/or funded.
      </p>
    </div>
  ) : (
    <div className="tooltip-content">
      <p className="tooltip-paragraph header">
        "Related features and assets" can be anything the customer interacts with:
      </p>
      <p className="tooltip-paragraph">
        These include application features, individual pages, and your marketing
        website. For example, "home page", "login", "password reset", and
        "sign-up email notification" can be considered related features and assets.
      </p>
      <p className="tooltip-paragraph">
        By using the <b>Related features and assets</b> field when managing a request, you
        aid internal visibility on where customer activity, requests, and issues
        are most common. The greater the visibility, the more likely the product gap
        is to get resolved to the delight of your customers.
      </p>
    </div>
  );
