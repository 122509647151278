import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import moment from 'moment';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import AddUserPane from '../../../components/pane/AddUserPane';
import { removeUser } from '../../../services/organization';
import { updateCurrentUserInvitation } from '../../../services/user_api';
import { toastifyConfiguration } from '../../../components/util/helpers';
import AboutPageDrawer from '../../AboutDrawers/MyInvitationsSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      invitations: [],
      invitationsCount: 0,
      isAboutPageDrawerOpen: false,
      accepted: undefined,
    };
    this.handleOnClickAcceptInvitation = this.handleOnClickAcceptInvitation.bind(this);
    this.handleGetPaginatedInvitations = this.handleGetPaginatedInvitations.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmAction = this.handleConfirmAction.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleGetPaginatedInvitations = async (page, pageSize) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchInvitations({
      page: page + 1,
      pageSize: pageSize,
      search,
    });
    this.setState({ loading: false });
  }

  handleConfirmAction = async (invitation, acceptStatus, event) => {
    event && event.preventDefault();
    const action = acceptStatus.accepted ? 'accept' : 'reject';
    this.setState({ accepted: acceptStatus.accepted });

    confirmAlert({
      customUI: ({ onClose }) => {``
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickAcceptInvitation}
            subtitle={`You want to ${action} this invitation?`}
            itemToRemove={invitation}
            confirmButtonText={`Yes, ${action} it!`}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickAcceptInvitation = async (invitation, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const { accepted } = this.state;
    const action = accepted ? 'accept' : 'reject';

    const response = await updateCurrentUserInvitation({
      organizationId: currentUser?.organization_id,
      userId: currentUser.id,
      id: invitation.id,
      accepted,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedInvitations(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(`Invitation ${action}ed successfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error ${action}ing the invitation. Please try again.`, toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getUsersCsv({ organization_id: currentUser?.organization_id });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    let {
      currentLocale,
      currentUser,
      invitations,
      invitationsCount,
      pages,
    } = this.props;

    const columns = [{
      accessor: 'inviter_organization_name',
      Header: 'Invited To Join Organization',
      sortable: false,
    }, {
      accessor: 'inviter_email',
      Header: 'Invited By',
      sortable: false,
    }, {
      id: 'created_at',
      Header: 'Invited On',
      sortable: false,
      accessor: invitation => <span>{moment(invitation.created_at).locale(currentLocale).format('YYYY-MM-DD')}</span>
    },  {
      id: 'accept',
      Header: 'Accept Invitation',
      sortable: false,
      accessor: invitation => (
        <div>
          <span
            className='edit-cell accept-invitation-cell'
            onClick={this.handleConfirmAction.bind(this, invitation, { accepted: true })}
          >
            <i className="bi bi-suitcase-fill"></i>
          </span>
        </div>
      )
    },
    {
      id: 'reject',
      Header: 'Reject Invitation',
      sortable: false,
      accessor: invitation => (
        <div>
          <span
            className='remove-cell'
            onClick={this.handleConfirmAction.bind(this, invitation, { accepted: false })}
          >
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="settings organization-users my-invitations container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Invitations</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>Total Invitations</div>
                <div className="count-container">{invitationsCount}</div>
              </div>
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={invitations}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedInvitations(state.page, state.pageSize);
              }}
            />
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings users-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
