import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-modal';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import NoResultImage from 'images/no_result.png';

import TableComponent from '../../../../components/common/TableComponent';
import FilterChannelsModal from '../../channel_list/filter_channels_modal';
import currencyCodesHash from '../../../../components/util/currency_codes_hash.json';
import withRouter from '../../../../util/with_router';
import {
  ellipsis,
  parseQueryString,
  removeEmptyFromObject,
  sleep,
  statusBadge,
  statusMapping,
} from '../../../util/helpers';
import { paramsToQueryString } from '../../../../services/util/helpers';

// client-side pagination
const PAGE_SIZE = 25;

class FilteredList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      sliceStart: 0,
      sliceEnd: PAGE_SIZE,
      currentPage: 1,
    };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleClearCriteria = this.handleClearCriteria.bind(this);
    this.handleGetTableProps = this.handleGetTableProps.bind(this);
    this.handleGetTbodyProps = this.handleGetTbodyProps.bind(this);
    this.handleOnOpenFilterChannelsModal = this.handleOnOpenFilterChannelsModal.bind(this);
    this.handleOnFilterChannels = this.handleOnFilterChannels.bind(this);
    this.void = this.void.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    const { location } = this.props;

    if (location?.search) {
      const filters = parseQueryString(location.search);
      await this.props.fetchChannels({ filters });
    }

    // NOTE: Await here b/c the fetch is really fast, thus isLoading
    // immediately is reset
    await sleep(300);
    this.setState({ isLoading: false });
  }

  handleItemClick(id) {
    const { workspaceId } = this.props;
    this.props.navigate(`/workspaces/${workspaceId}/requests/${id}`);
  }

  handleClearCriteria() {
    const { workspaceId } = this.props;
    this.props.navigate(`/workspaces/${workspaceId}/requests/filter?requests=all`)
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  void = () => {
    return;
  }

  handleOnOpenFilterChannelsModal = () => {
    const {
      currentUser,
      isEducationOrganizationPersona,
      location,
      organizationPersona,
      workspaceId,
    } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <FilterChannelsModal
            currentUser={currentUser}
            isEducationOrganizationPersona={isEducationOrganizationPersona}
            organizationPersona={organizationPersona}
            location={location}
            onClick={this.handleOnFilterChannels}
            onClose={onClose}
            workspaceId={workspaceId}

          />
        );
      }
    });
  }

  handleOnFilterChannels = ({ filterValuesSelected }) => {
    const { workspaceId } = this.props;

    const queryString = paramsToQueryString(
      removeEmptyFromObject(filterValuesSelected),
    );

    this.props.navigate(`/workspaces/${workspaceId}/requests/filter?${queryString || 'requests=all'}`)
  }

  render() {
    const { isLoading, sliceStart, sliceEnd, currentPage } = this.state;
    const {
      channels,
      currentLocale,
      isEducationOrganizationPersona,
      location,
      totalCount,
      workspaceId,
    } = this.props;

    const count = channels?.length || 0
    const filters = parseQueryString(location.search);
    const isListFiltered = !(!filters || filters?.requests === 'all');

    const columns = [{
      id: 'name',
      Header: 'Request',
      width: 400,
      accessor: request => (
        <a
          href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${request.id}`}
        >
          {request.name}
        </a>
      ),
    },
    {
      id: 'created_at',
      accessor: request => <span>{moment(request.created_at).locale(currentLocale).format('YYYY-MM-DD')}</span>,
      Header: 'Created at',
    }, {
      id: 'status',
      Header: 'Status',
      width: 120,
      accessor: request => (
        <div className={`status pill badge ${statusBadge(request.status)} d-flex justify-content-center align-items-center`}>
          {statusMapping(request.status)}
        </div>
      ),
    },
    ...!isEducationOrganizationPersona ? [{
      id: 'deal_value',
      Header: 'Deal value',
      width: 140,
      accessor: request => <span>{handleDealValue(request)}</span>
    }] : [],
    ...!isEducationOrganizationPersona ? [{
      id: 'customers',
      Header: '# of customers',
      width: 100,
      accessor: request => (
        <span>{tagsCount({ channel: request, type: 'customer' })}</span>
      ),
    }] : [],
    ...isEducationOrganizationPersona ? [{
      id: 'safety_issue',
      Header: 'Satefy issue?',
      width: 120,
      accessor: request => <span>{request.safety_issue ? 'Yes' : 'No'}</span>
    }] : [],
    ...isEducationOrganizationPersona ? [{
      id: 'alert',
      Header: 'Is alert?',
      width: 100,
      accessor: request => <span>{request.alert ? 'Yes' : 'No'}</span>
    }] : [],
    ...!isEducationOrganizationPersona ? [{
      id: 'number_of_teams',
      Header: '# of teams assigned',
      accessor: request => <span>{tagsCount({ channel: request, type: 'team' })}</span>
    }] : [],
  ];

    if (isLoading) {
      return (
        <div className="summary-list-view filtered-channels-list-view" ref={ref => this.el = ref}>
          <div className="loading-spinner-container d-flex align-items-center justify-content-center bg-white vh-100">
            <Spinner animation="border" role="status" variant="secondary" />
          </div>
        </div>
      );
    }

    return (
      <div className="summary-list-view filtered-channels-list-view mb-5" ref={ref => this.el = ref}>
        <div className="header d-flex justify-content-between align-items-center">
          <div className="filter-result-title d-flex align-items-center mb-4">
            <div className="filter-header me-2">Filter result</div>
            <div className="count filter-count badge rounded-pill me-2">
              {count}
            </div>
            <div className="header-container d-flex align-items-center me-2">
              <i
                className="bi bi-filter requests-filter-icon"
                onClick={() => this.handleOnOpenFilterChannelsModal()}
              />
            </div>
            {isListFiltered && (
              <div className="clear-criteria" onClick={this.handleClearCriteria}>
                Clear filter
              </div>
            )}
          </div>
        </div>
        <div className="settings organization-workspaces customer-tags container-fluid">
          <div className="content-container mt-2">
            <div className="container-fluid table-details-section">
              <TableComponent
                columns={columns}
                data={channels}
                defaultPageSize={PAGE_SIZE}
                loading={isLoading}
                manual
                pages={numberOfPages({ totalCount })}
                handleOnFetchData={() => this.void}
                noDataText="Sorry. We didn't find any results for your filter criteria."
                // handleSelection={this.handleSelection}
                handleGetTableProps={this.handleGetTableProps}
                handleGetTbodyProps={this.handleGetTbodyProps}
                rowSelectionEnabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FilteredList);

const pagedTabClassNames = ({ currentPage, number }) =>
  currentPage === number + 1 ? 'page-link active' : 'page-link';

const previousTabClassNames = ({ currentPage }) =>
  currentPage === 1 ? 'page-item disabled' : 'page-item';

const nextTabClassNames = ({ currentPage, totalCount }) => {
  const pagesCount = numberOfPages({ totalCount });
  return currentPage === pagesCount ? 'page-item disabled' : 'page-item';
};

const numberOfPages = ({ totalCount }) => Math.ceil(totalCount / PAGE_SIZE);

const handlePaginationNumbersArray = ({ totalCount }) => {
  const pagesCount = numberOfPages({ totalCount });
  const arrayOfNumbers = [...Array(pagesCount).keys()];
  return arrayOfNumbers;
};

const handleSliceStart = ({ number }) => {
  return PAGE_SIZE * number;
};

const handleSliceEnd = ({ currentPage }) => {
  return PAGE_SIZE * currentPage;
};

const handleDealValue = channel => {
  const opportunityValue = channel?.opportunity_value;
  const opportunityValueCurrency = channel?.opportunity_value_currency;
  const currencySymbol = opportunityValue && currencyCodesHash[opportunityValueCurrency];
  return opportunityValue
    ? `${currencySymbol || ''} ${opportunityValue.toLocaleString()}`
    : 'N/A';
};

const tagsCount = ({ channel, type }) => {
  const { tags } = channel;
  const tagsByType = tags?.filter(tag => tag.tag_type === type);
  return tagsByType?.length || 0;
};
