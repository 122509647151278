import {
  RECEIVE_CURRENT_USER,
  RECEIVE_CURRENT_USER_INVITATIONS
} from '../actions/user_actions';

const currentUserReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return {
        ...state,
        ...action.user.currentUser,
        featuresEnabled: action.user.currentUser.features_enabled,
      }
    case RECEIVE_CURRENT_USER_INVITATIONS:
      return {
        ...state,
        invitations: {
          entries: action.invitation.entries,
          pages: action.invitation.total_pages,
          count: action.invitation.total_count,
        },
      }
    default:
      return state;
  };
};

export default currentUserReducer;
