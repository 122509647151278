import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import MessageFormContainer from './message_form/message_form_container';
import ChannelHeaderContainer from './channel_header/channel_header_container';
import MessageListContainer from './message_list/message_list_container';
import ChannelMetadataContainer from './channel_metadata/channel_metadata_container';
import FilteredListContainer from './filtered_list/filtered_list_container';

class Channel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      channelId,
      workspaceId,
      isChannelsLoading,
      actionCabelUrl,
      location,
    } = this.props;

    const isFilteredChannelsView =
      location?.pathname === `/workspaces/${workspaceId}/requests/filter`;

    if (isChannelsLoading) {
      return (
        <div className="channel-main">
          <div className="loading-spinner-container">
            <Spinner animation="border" role="status" variant="secondary" />
          </div>
        </div>
      );
    }

    if (isFilteredChannelsView) {
      return (
        <div className="channel-main channels-summary-view">
          <FilteredListContainer
            currentUser={currentUser}
            key={`channels-list-filter-${location?.search}`}
          />
        </div>
      );
    }

    return (
      <>
        <div className="col-12 col-lg-6 d-flex flex-column p-3">
          <div className="mb-3">
            <div className="bg-light p-3 rounded">
              <ChannelHeaderContainer
                currentUser={currentUser}
                key={`channel-header-${channelId}`}
              />
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto" style={{ maxHeight: '50vh '}}>
            <MessageListContainer
              actionCabelUrl={actionCabelUrl}
              key={`message-list-${channelId}`}
            />
          </div>

          <div className="mt-3">
            <MessageFormContainer
              currentUser={currentUser}
              actionCabelUrl={actionCabelUrl}
            />
            {/*
              <form>
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Type a message" />
                  <button className="btn btn-primary" type="submit">Send</button>
                </div>
              </form>
            */}
          </div>
        </div>
        <div className="col-12 col-lg-3 p-3 border">
          <div className="h-100">
            <ChannelMetadataContainer currentUser={currentUser} />
          </div>
        </div>

        {/*
          <div className="col-12 col-lg-3 p-3 border">
            <div className="h-100">
              <ChannelListContainer
                key={`channel-list-${workspaceId}`}
                currentUser={currentUser}
                currentLocale={currentLocale}
              />
            </div>
          </div>
        */}

      {/*
        </div>
          <div className="channel-main">
            <ChannelHeaderContainer
              currentUser={currentUser}
              key={`channel-header-${channelId}`}
            />
            <MessageListContainer
              actionCabelUrl={actionCabelUrl}
              key={`message-list-${channelId}`}
            />
            <MessageFormContainer
              currentUser={currentUser}
              actionCabelUrl={actionCabelUrl}
            />
            <ChannelMetadataContainer currentUser={currentUser} />
          </div>
      */}
      </>
    );
  }
}

export default Channel;
