import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Routes, Route } from 'react-router-dom';

import MainContainer from './main/main_container';
import ProfileSettingContainer from './my_settings/profile/profile_container';
import EmailPreferencesSettingContainer from './my_settings/email_preferences/email_preferences_container';
import LicenseSettingsSettingContainer from './my_settings/license_settings/license_settings_container';

import MyInvitationSettingContainer from './my_settings/my_invitations/invitations_container';
import AccessManagementSettingContainer from './my_settings/organization_access_management/access_management_container';
import AccessManagementTeamSettingContainer from './my_settings/organization_team_access_management/team_container';
import AccessManagementWorkspaceSettingContainer from './my_settings/organization_workspace_access_management/workspace_container';
import SecuritySettingContainer from './my_settings/security/security_container';
import AuditLogsSettingContainer from './my_settings/audit_logs/audit_logs_container';
import TwoFactorSettingsContainer from './my_settings/two_factor_settings/two_factor_settings_container';
import TwoFactorSettingsBackupCodesContainer from './my_settings/two_factor_settings_backup_codes/two_factor_settings_backup_codes_container';
import WebhooksContainer from './my_settings/webhooks/webhooks_container';
import IntegrationsContainer from './my_settings/integrations/integrations_container';
import CustomerTagsContainer from './my_settings/organization_customer_tags/customer_tags_container';
import HomeDashboardContainer from './Dashboard/home_dashboard/home_dashboard_container';
import PricingPlansContainer from './my_settings/pricing_plans/pricing_plans_container';
import RequestsContainer from './my_settings/organization_requests/requests_container';
import IntranetOrganizationsContainer from './Intranet/organizations/organizations_container';
import IntranetOrganizationUsersContainer from './Intranet/organization_users/organization_users_container';

import NotFound from './common/errors/NotFound';
import NotAuthorized from './common/errors/NotAuthorized';
import Modal from './modal/modal';
import { ProtectedRoute } from '../util/route_util';
import {
  isCurrentUserAccountAdmin,
  isCurrentUserCustomerManager,
  isCurrentUserFromContactImmed,
  isCurrentUserWorkspaceManager,
  isEducationOrganizationPersona,
} from '../reducers/selectors';


// replacement for optional parameter in React Router v6.
// const registerOptionalLocaleParam = (path, element) => {
//     const pathWithLocale = `/:locale${path}`
//     return (
//       <Fragment>
//         <Route exact path={path} key={path} element={element} />
//         <Route exact path={pathWithLocale} key={pathWithLocale} element={element} />
//       </Fragment>
//     );
// };

const registerOptionalLocaleParam = (path, element) => {
  return (
    <Route path={path} key={path} element={element} />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      currentUserOrganization,
      permittedRoles,
      permittedTeamRoles,
      permittedWorkspaceRoles,
      lastVisitedChannel,
      lastVisitedWorkspace,
      currentLocale,
      actionCabelUrl,
    } = this.props;

    const entities = { currentUser };

    const { featuresEnabled } = currentUser;

    const isAccountAdmin = isCurrentUserAccountAdmin({ entities });
    const isWorkspaceManager = isCurrentUserWorkspaceManager({ entities });
    const isCustomerManager = isCurrentUserCustomerManager({ entities });
    const isFromContactImmed = isCurrentUserFromContactImmed({ entities });
    const isEducationOrganization = isEducationOrganizationPersona({ entities });

    const canManageUsers = isAccountAdmin || isWorkspaceManager;

    return (
      <Fragment>
        <Modal />
        <Routes>
          {/* <Route exact path="/" element={<MainContainer currentUser={currentUser} />} /> */}
          {registerOptionalLocaleParam(
            '/',
            <ProtectedRoute>
              <HomeDashboardContainer
                currentUser={currentUser}
                currentUserOrganization={currentUserOrganization}
                lastVisitedChannel={lastVisitedChannel}
                lastVisitedWorkspace={lastVisitedWorkspace}
                currentLocale={currentLocale}
              />
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/dashboard-onboarding-demo',
            <ProtectedRoute>
              <HomeDashboardContainer
                currentUser={currentUser}
                currentUserOrganization={currentUserOrganization}
                lastVisitedChannel={lastVisitedChannel}
                lastVisitedWorkspace={lastVisitedWorkspace}
                currentLocale={currentLocale}
                useDemoData
              />
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/workspaces/:workspaceId/requests/:channelId/*',
            <ProtectedRoute>
              <MainContainer
                currentUser={currentUser}
                currentLocale={currentLocale}
                actionCabelUrl={actionCabelUrl}
              />
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/workspaces/:workspaceId/requests/filter',
            <ProtectedRoute>
              <MainContainer
                currentUser={currentUser}
                currentLocale={currentLocale}
                actionCabelUrl={actionCabelUrl}
              />
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/my-profile/*',
            <ProtectedRoute>
              <ProfileSettingContainer
                currentUser={currentUser}
                currentUserOrganization={currentUserOrganization}
                currentLocale={currentLocale}
              />
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/my-invitations/*',
            <ProtectedRoute>
              <MyInvitationSettingContainer
                currentUser={currentUser}
                currentUserOrganization={currentUserOrganization}
                currentLocale={currentLocale}
              />
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/access-management/*',
            <ProtectedRoute>
              {canManageUsers
                ? <AccessManagementSettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                    isAccountAdmin={isAccountAdmin}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/access-management/teams/:teamId/*',
            <ProtectedRoute>
              {canManageUsers
                ? <AccessManagementTeamSettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    permittedTeamRoles={permittedTeamRoles}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/access-management/workspaces/:workspaceId/*',
            <ProtectedRoute>
              {canManageUsers
                ? <AccessManagementWorkspaceSettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    permittedWorkspaceRoles={permittedWorkspaceRoles}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/customer-tags/*',
            <ProtectedRoute>
              {!isEducationOrganization && (isAccountAdmin || isCustomerManager)
                ? <CustomerTagsContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/requests/*',
            <ProtectedRoute>
              {canManageUsers
                ? <RequestsContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/security/*',
            <ProtectedRoute>
              {isAccountAdmin && featuresEnabled?.two_factor_authentication
                ? <SecuritySettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/email_preferences/*',
            <ProtectedRoute>
              {featuresEnabled?.email_preferences
                ? <EmailPreferencesSettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }

            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/license_settings/*',
            <ProtectedRoute>
              {!isEducationOrganization && isAccountAdmin && featuresEnabled?.license_settings
                ? <LicenseSettingsSettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/audit-logs/*',
            <ProtectedRoute>
              {isAccountAdmin && featuresEnabled?.audit_logs
                ? <AuditLogsSettingContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/two-factor-settings/*',
            <ProtectedRoute>
              {featuresEnabled?.two_factor_authentication
                ? <TwoFactorSettingsContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>
          )}
          {registerOptionalLocaleParam(
            '/settings/two-factor-settings-backup-codes/*',
            <ProtectedRoute>
              {featuresEnabled?.two_factor_authentication
                ? <TwoFactorSettingsBackupCodesContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>,
          )}
          {registerOptionalLocaleParam(
            '/settings/webhooks/*',
            <ProtectedRoute>
              {isAccountAdmin && featuresEnabled?.webhooks_enabled
                ? <WebhooksContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>,
          )}

          {registerOptionalLocaleParam(
            '/settings/integrations/*',
            <ProtectedRoute>
              {isAccountAdmin && !isEducationOrganization && featuresEnabled?.integrations_enabled
                ? <IntegrationsContainer
                    currentUser={currentUser}
                    currentUserOrganization={currentUserOrganization}
                    currentLocale={currentLocale}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
            </ProtectedRoute>,
          )}

          {registerOptionalLocaleParam(
            '/settings/pricing/*',
            <ProtectedRoute>
              {isAccountAdmin && !isEducationOrganization && featuresEnabled?.pricing_plans
                ? <PricingPlansContainer
                    currentUser={currentUser}
                    currentLocale={currentLocale}
                    currentUserOrganization={currentUserOrganization}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
             </ProtectedRoute>,
            )
          }
          {registerOptionalLocaleParam(
            '/intranet/organizations',
            <ProtectedRoute>
              {isFromContactImmed
                ? <IntranetOrganizationsContainer
                    currentUser={currentUser}
                    currentLocale={currentLocale}
                    currentUserOrganization={currentUserOrganization}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
             </ProtectedRoute>,
            )
          }
          {registerOptionalLocaleParam(
            '/intranet/organizations/:organizationId/*',
            <ProtectedRoute>
              {isFromContactImmed
                ? <IntranetOrganizationUsersContainer
                    currentUser={currentUser}
                    currentLocale={currentLocale}
                    currentUserOrganization={currentUserOrganization}
                  />
                : <NotAuthorized
                    currentLocale={currentLocale}
                    currentUser={currentUser}
                  />
              }
             </ProtectedRoute>,
            )
          }

          <Route path='/*' element={<NotFound currentLocale={currentLocale} currentUser={currentUser} />} />
        </Routes>
      </Fragment>
    );
  }
}

export default App
