import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Field, FormSpy } from 'react-final-form'
import Wizard from '../../components/FormWizard/wizard';
import WizardIntroductionStep from './wizard_introduction_step';
import WizardDashboardStepHeader from './wizard_dashboard_step_header';
import WizardRequestCreationStepHeader from './wizard_request_creation_step_header';
import WizardAccessManagementStepHeader from './wizard_access_management_step_header';
import WizardCustomerManagementStepHeader from './wizard_customer_management_step_header';
import WizardMissionCompleteStep from './wizard_mission_complete_step';

import { sleep } from '../../components/util/helpers';
import {
  assignWorkspaceToUser,
  requestAccessToOrganizationTeams,
  requestAccessToOrganizationWorkspaces,
} from '../../services/organization';

const onSubmit = async values => {
  await sleep(300);
  return;
}

const alreadyAssignedClass = option =>
  option.alreadyAssigned ? 'workspace-already-assigned' : ''

class ActivationWizardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceItems: props.organizationWorkspaceNames?.map(name => ({
        value: name,
        label: name,
        checked: false,
        alreadyAssigned: !!props.userWorkspaces?.filter(workspace =>
          workspace.name?.toLowerCase() === name.toLowerCase(),
        )?.length,
      })) || [{}],
      teamItems: props.teamsWithinUserWorkspaces?.map(team => ({
        value: team.id,
        label: `${team.name} - ${team.workspace_name}`,
        checked: false,
        alreadyAssigned: team.is_already_assigned,
      })) || [{}],
      isTeamAccessRequestSubmitting: false,
      isWorkspaceAccessRequestSubmitting: false,
    };
    this.handleRequestAccessToWorkspaces = this.handleRequestAccessToWorkspaces.bind(this);
    this.handleSelectWorkspaceItem = this.handleSelectWorkspaceItem.bind(this);
    this.handleSelectTeamItem = this.handleSelectTeamItem.bind(this);
    this.handleRequestAccessToTeams = this.handleRequestAccessToTeams.bind(this);
  }

  handleRequestAccessToWorkspaces = async (values) => {
    await sleep(300);
    const { workspaceItems } = this.state;
    const workspaceAccessRequestList = workspaceItems.filter(item =>
      !item.alreadyAssigned && item.checked
    )

    if (!workspaceAccessRequestList || workspaceAccessRequestList.length === 0) {
      return;
    }

    const { currentUser } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await requestAccessToOrganizationWorkspaces({
      organization_id,
      workspace_access_request_list: workspaceAccessRequestList.map(list => list.value),
    });

    const { success } = response || {};

    if (success) {
      this.setState({ workspaceRequestSubmitted: true });
    }
    this.setState({ isWorkspaceAccessRequestSubmitting: false });
  }

  handleRequestAccessToTeams = async (values) => {
    await sleep(300);
    const { teamItems } = this.state;
    const teamAccessRequestList = teamItems.filter(item =>
      !item.alreadyAssigned && item.checked
    )

    if (!teamAccessRequestList || teamAccessRequestList.length === 0) {
      return;
    }

    const { currentUser } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await requestAccessToOrganizationTeams({
      organization_id,
      team_access_request_list: teamAccessRequestList.map(list => list.value),
    });

    const { success } = response || {};

    if (success) {
      this.setState({ teamRequestSubmitted: true });
    }
    this.setState({ isTeamAccessRequestSubmitting: false });
  }

  handleSelectWorkspaceItem = (event) => {
    const itemValue = event?.target?.name;
    const isChecked = event?.target?.checked;

    this.setState((prevState) => ({
      workspaceItems: prevState.workspaceItems.map((item) => {
        if (`${item.value}` === itemValue) {
          return { ...item, checked: isChecked };
        } else {
          return item;
        }
      }),
    }));
  }

  handleSelectTeamItem = (event) => {
    const itemValue = event?.target?.name;
    const isChecked = event?.target?.checked;

    this.setState((prevState) => ({
      teamItems: prevState.teamItems.map((item) => {
        if (`${item.value}` === itemValue) {
          return { ...item, checked: isChecked };
        } else {
          return item;
        }
      }),
    }));
  }

  render() {
    const {
      currentLocale,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      isEducationOrganizationPersona,
      myWorkspaces,
      onClose,
      organizationWorkspaces,
      userWorkspaces,
      workspaceId,
    } = this.props;

    const {
      isTeamAccessRequestSubmitting,
      isWorkspaceAccessRequestSubmitting,
      newRequestId,
      teamItems,
      teamRequestSubmitted,
      workspaceItems,
      workspaceRequestSubmitted,
    } = this.state;

    const doesUserHaveAccessToEveryWorkspace =
      isCurrentUserAccountAdmin ||
      workspaceItems?.filter(item =>
        !item.alreadyAssigned
      ).length === 0;

    // const doesUserHaveAccessToEveryWorkspace = false;

    const doesUserHaveAccessToAnyWorkspace =
      isCurrentUserAccountAdmin ||
      workspaceItems?.filter(item =>
        item.alreadyAssigned
      ).length > 0;

    // const doesUserHaveAccessToAnyWorkspace = false;

    const canUserCreateUsersOrTeams =
      isCurrentUserAccountAdmin || isCurrentUserWorkspaceManager;

    return (
      <div className="activation-wizard-modal activation-wizard custom-ui">
        <div className="h1 mb-4">Activation Launchpad</div>
        <Wizard
          onSubmit={onSubmit}
          omitFinalOnSubmit={true}
        >
          <Wizard.Page>
            <WizardIntroductionStep
              canUserCreateUsersOrTeams={canUserCreateUsersOrTeams}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Wizard.Page>
          <Wizard.Page>
            <div className="mb-2 wizard-section-header text-start">
              <span className="bold">Dashboard overview</span>{' '}
              <span className="activation-link-to-section">Link to your workspace dashboard here{' '}
                <a
                  href={`/#/${currentLocale}?workspace_id=${workspaceId}`}
                  target="_blank"
                >
                  <i className="bi bi-box-arrow-up-right"></i>
                </a>
              </span>
            </div>
            <WizardDashboardStepHeader
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Wizard.Page>
          {isEducationOrganizationPersona && (
            <Wizard.Page>
              <div className="mb-2 bold wizard-section-header text-start">
                Workspace access
              </div>
              <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
                <div>
                  Workspaces represent the various school entities within your
                  district. The workspaces you have access to are listed in the
                  navigation bar and below the Dashboard panel on the homepage
                </div>
                <div className="mt-3">
                  If you need access to additional workspaces to perform your
                  duties, you can request them here. For example, a high school
                  teacher may only need access to the high school workspace,
                  while a custodial team member might need access to multiple
                  school workspaces to manage facilities across the district.
                </div>
              </div>
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    {doesUserHaveAccessToEveryWorkspace ? (
                      <label className="mb-2">
                        Congratulations! You have full access to all workspaces
                        and can skip this step.
                      </label>
                    ) : (
                      <>
                        <label className="mb-2">
                          Select a workspace if you need access. A request will
                          be submitted to your admin, who will either approve
                          or deny it.
                        </label>
                        <div className="workspace-list-container d-flex row">
                          {workspaceItems.map((option) => (
                            <label className="mt-1" key={option.value}>
                              <input
                                className={`me-2 workspace-checkbox ${alreadyAssignedClass(option)}`}
                                type="checkbox"
                                name={`${option.value}`}
                                checked={option.checked}
                                onChange={this.handleSelectWorkspaceItem}
                                disabled={option.alreadyAssigned}
                              />
                              <span className={`workspace-checkbox-label ${alreadyAssignedClass(option)}`}>
                                {option.label}
                              </span>
                              {option.alreadyAssigned && (
                                <span className="ms-1 workspace-already-assigned-label">
                                  *You are already a member of this workspace
                                </span>
                              )}
                            </label>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!doesUserHaveAccessToEveryWorkspace && (
                <>
                  {workspaceRequestSubmitted ? (
                    <div className="text-start py-4 px-3 step-completion-container mt-4">
                      Congratulations on successfully requesting access to the
                      workspace(s)! <b>If you do not currently have access to any
                      workspace, admin approval will be required before you can
                      proceed with the activation guide</b>. You will receive an
                      email notification once access is granted.
                    </div>
                  ) : (
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => isWorkspaceAccessRequestSubmitting ? (
                        <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting" disabled={true}>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          <span>Submitting...</span>
                        </button>
                      ) : (
                        <button
                          className="submit-button mt-4"
                          onClick={() => {
                          this.setState({ isWorkspaceAccessRequestSubmitting: true });
                          this.handleRequestAccessToWorkspaces(values);
                        }}>
                          Request Access
                        </button>
                      )}
                    </FormSpy>
                  )}
                </>
              )}

            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && (
            <Wizard.Page>
              <div className="mb-2 bold wizard-section-header text-start">
                <span>Requests overview</span>{' '}
                <span className="activation-link-to-section">Link to workspace requests here{' '}
                  <a
                    href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?requests=all`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </span>
              </div>
              <WizardRequestCreationStepHeader
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && canUserCreateUsersOrTeams && (
            <Wizard.Page>
              <div className="mb-2 bold wizard-section-header text-start">
                <span>Access management overview</span>{' '}
                <span className="activation-link-to-section">Link to access management here{' '}
                  <a
                    href={`/#/${currentLocale}/settings/access-management`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </span>
              </div>
              <WizardAccessManagementStepHeader
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
            </Wizard.Page>
          )}

          {!isEducationOrganizationPersona && doesUserHaveAccessToAnyWorkspace && canUserCreateUsersOrTeams && (
            <Wizard.Page>
              <div className="mb-2 bold wizard-section-header text-start">
                <span>Customer management overview</span>{' '}
                <span className="activation-link-to-section">Link to customer management here{' '}
                  <a
                    href={`/#/${currentLocale}/settings/customer-tags`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                  {/* (for users who rep customers and prospects) */}
                </span>
              </div>
              <WizardCustomerManagementStepHeader />
            </Wizard.Page>
          )}

          {doesUserHaveAccessToAnyWorkspace && isEducationOrganizationPersona && (
            <Wizard.Page>
              <div className="mb-2 bold wizard-section-header text-start">
                Team access
              </div>
              <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
                <div className="mb-3">
                  Teams are groups within an individual school workspace that
                  help organize coworkers by their areas of responsibility. This
                  structure mirrors the district’s organizational setup,
                  ensuring that teams are aligned with key functions and roles.
                </div>
                <div className="mb-3">
                  Requests can be assigned to specific teams using the
                  "Recommended Teams" option, allowing them to quickly reach the
                  team(s) best equipped to handle and collaborate on the issue.
                </div>
                <div className="bold mb-1">Joining additional teams</div>
                <div className="mb-3">
                  If there are other teams you need to join to represent your
                  role(s) in the district, we've listed them here. For example,
                  a school principal may need access to a "Principal's Office"
                  team within their school, while a food service employee may
                  require access to the "Food Service" team.
                </div>
                <div className="mb-3">
                  Some users, such as IT or maintenance personnel, may need
                  access to the same team across multiple workspaces. If you
                  don't see the team you need listed, please contact your admin
                  to have it created.
                </div>
              </div>
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">
                      Select a team if you need access. A request
                      will be submitted to your admin, who will either approve
                      or deny it.
                    </label>
                    <div className="workspace-list-container d-flex row">
                      {teamItems.map((option) => (
                        <label className="mt-1" key={option.value}>
                          <input
                            className={`me-2 workspace-checkbox ${alreadyAssignedClass(option)}`}
                            type="checkbox"
                            name={`${option.value}`}
                            checked={option.checked}
                            onChange={this.handleSelectTeamItem}
                            disabled={option.alreadyAssigned}
                          />
                          <span className={`workspace-checkbox-label ${alreadyAssignedClass(option)}`}>
                            {option.label}
                          </span>
                          {option.alreadyAssigned && (
                            <span className="ms-1 workspace-already-assigned-label">
                              *You are already a member of this team
                            </span>
                          )}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                {teamRequestSubmitted ? (
                  <div className="text-start py-4 px-3 step-completion-container mt-4">
                    Congratulations on successfully requesting access to the
                    team(s)! You will receive an email notification once
                    access is granted.
                  </div>
                ) : (
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => isTeamAccessRequestSubmitting ? (
                      <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting" disabled={true}>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span>Submitting...</span>
                      </button>
                    ) : (
                      <button
                        className="submit-button mt-4"
                        onClick={() => {
                        this.setState({ isTeamAccessRequestSubmitting: true });
                        this.handleRequestAccessToTeams(values);
                      }}>
                        Request Access
                      </button>
                    )}
                  </FormSpy>
                )}
              </div>
            </Wizard.Page>
          )}
          {doesUserHaveAccessToAnyWorkspace && (
            <Wizard.Page>
              <WizardMissionCompleteStep
                currentLocale={currentLocale}
                isEducationOrganizationPersona={isEducationOrganizationPersona}
                newRequestId={newRequestId}
                workspaceId={workspaceId}
              />
            </Wizard.Page>
          )}
        </Wizard>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-2 close-wizard-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

ActivationWizardModal.propTypes = {
  handleChoosePlan: PropTypes.func,
  planToChoose: PropTypes.string,
  onClose: PropTypes.func,
};

export default ActivationWizardModal;
