import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddWorkspacePane from '../../../components/pane/AddWorkspacePane';
import { removeWorkspace, getWorkspaces, getWorkspacesCsv } from '../../../services/workspace';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import TableComponent from '../../../components/common/TableComponent';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationWorkspaces.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedWorkspace: {},
      search: '',
      workspaces: [], // props.organizationWorkspaces.entries,
      workspacesCount: 0, // props.organizationWorkspaces.total_count,
      isCsvActionInProgress: false,
      selection: [],
    };
    this.handleOnClickAddWorkspace = this.handleOnClickAddWorkspace.bind(this);
    this.onAddWorkspacePaneClose = this.onAddWorkspacePaneClose.bind(this);
    this.handleOnClickEditWorkspace = this.handleOnClickEditWorkspace.bind(this);
    this.handleOnClickRemoveWorkspace = this.handleOnClickRemoveWorkspace.bind(this);
    this.handleWorkspacesSearch = this.handleWorkspacesSearch.bind(this);
    this.handleWorkspacesFilterFetch = this.handleWorkspacesFilterFetch.bind(this);
    this.handleGetPaginatedWorkspaces = this.handleGetPaginatedWorkspaces.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleWorkspacesFilterFetch(input), 500);

  handleWorkspacesSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleWorkspacesFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredWorkspaces = await this.props.fetchWorkspaces({ search: input });
    if (filteredWorkspaces) {
      this.setState({ workspaces: filteredWorkspaces.entries || [], search: input });
    }
  }

  handleGetPaginatedWorkspaces = async (page, pageSize, sorted) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchWorkspaces({
      page: page + 1,
      pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddWorkspace = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedWorkspace: {} });
  }

  onAddWorkspacePaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditWorkspace = workspace => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedWorkspace: workspace });
  }

  handleConfirmDelete = async (workspace, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveWorkspace}
            subtitle="You want to delete this workspace?"
            itemToRemove={workspace}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveWorkspace = async (workspace, event) => {
    const { currentUser } = this.props;
    const response = await removeWorkspace({
      organization_id: currentUser?.organization_id,
      workspace_id: workspace.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedWorkspaces(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Workspace removed successfully.', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the workspace. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getWorkspacesCsv({ organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleGetTableProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleGetTbodyProps = () => {
    return {
      style: { overflow: 'visible' },
    };
  };

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginatedWorkspaces(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {
    let {
      currentLocale,
      currentUser,
      isEducationOrganizationPersona,
      organizationWorkspaces,
      pages,
      permittedRoles,
      workspaces,
      workspacesCount,
      // Note: Only enterprise accounts can add workspaces
      canAddWorkspaces,
      isAccountAdmin,
    } = this.props;

    if (!isAccountAdmin) {
      return;
    }

    let {
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedWorkspace,
    } = this.state;

    const columns = [{
      id: 'name',
      Header: 'Name',
      width: 400,
      accessor: t => <a className='workspace-name' href={`/#/${currentLocale}/settings/access-management/workspaces/${t.id}`}>{t.name}</a>
    }, {
      id: 'created_at',
      Header: 'Created at',
      accessor: t => <span>{moment(t.created_at).locale(currentLocale).format('YYYY-MM-DD')}</span>
    }, {
      id: 'user_count',
      Header: 'Member count',
      width: 200,
      sortable: false,
      accessor: t => <span className='user-count'>{(t.user_count)}</span>
    },
    ...isEducationOrganizationPersona ? [{
        Header: 'Clever school ID',
        sortable: false,
        accessor: 'clever_school_id',
      }] : [],
    {
      id: 'action',
      Header: 'Action',
      width: 120,
      sortable: false,
      accessor: t => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditWorkspace.bind(this, t)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          {canAddWorkspaces && (
            <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
              <i className="bi bi-trash3"></i>
            </span>
          )}
        </div>
      )
    }];

    return (
      <div className="mt-5" ref={ref => this.el = ref}>
        <div className="row mb-4 resource-metadata">
          <div className="col-md-4 column-width">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Workspaces</div>
                  <div className="d-flex">
                    <i className="bi bi-building me-1"></i>
                    <div className="card-count">{workspacesCount}</div>
                  </div>
                </div>
                <div>
                  Organization subdivisions
                </div>
              </div>
            </div>
          </div>
          {canAddWorkspaces && (
            <div className="col-md-2 add-resource-column-width workspaces">
              <div className="card">
                <div className="card-body d-flex justify-content-center align-items-center">
                  <div onClick={this.handleOnClickAddWorkspace} className="add-resource-action">
                    + Add workspace
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="filter-actions-and-export d-flex justify-content-between">
          {canAddWorkspaces && (
            <>
              <div className="search">
                <input
                  type="text"
                  className="form-control search-input"
                  onChange={this.handleWorkspacesSearch}
                  placeholder="Search workspaces" />
              </div>
              <div className="ms-2 button-actions">
                {isCsvActionInProgress ? (
                  <button className="export export-csv workspaces" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Exporting...</span>
                  </button>
                ) : (
                  <Button
                    handleClick={() => {
                      this.setState({ isCsvActionInProgress: true });
                      this.handleCsvExport();
                    }}
                    label="Export CSV"
                    classes="export export-csv"
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className="container-fluid table-details-section">
          <TableComponent
            columns={columns}
            data={workspaces}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            loading={this.state.loading}
            pages={pages}
            handleOnFetchData={this.handleOnFetchData}
            handleSelection={this.handleSelection}
            handleGetTableProps={this.handleGetTableProps}
            handleGetTbodyProps={this.handleGetTbodyProps}
            rowSelectionEnabled={false}
          />
          <SlidingPane
            className='add-workspace-pane'
            overlayClassName='sliding-pane-overlay'
            isOpen={ this.state.isPaneOpen }
            title={isEditMode ? 'Edit Workspace' : 'Create New Workspace'}
            width='60%'
            subtitle=''
            onRequestClose={this.onAddWorkspacePaneClose}>
              <AddWorkspacePane
                handleGetPaginatedWorkspaces={this.handleGetPaginatedWorkspaces}
                currentUser={currentUser}
                onAddWorkspacePaneClose={this.onAddWorkspacePaneClose}
                isEditMode={isEditMode}
                isEducationOrganizationPersona={isEducationOrganizationPersona}
                selectedWorkspace={selectedWorkspace}
                permittedRoles={permittedRoles}
              />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
