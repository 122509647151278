import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import WizardVideoExplainer from './wizard_video_explainer';

const B2B_DASHBOARD_OVERVIEW_VIDEO = 'https://www.loom.com/embed/ec857cae7a2f46fd9125f9765ab35c3a?sid=37d8dc49-1328-4e86-be77-fba336389a78';
const ED_DASHBOARD_OVERVIEW_VIDEO = 'https://www.loom.com/embed/e211c85e3f4e48138ba47aef52ff7a39?sid=dfa0f44a-17f0-4864-931c-acf27652375c';

class WizardDashboardStepHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isEducationOrganizationPersona } = this.props;

    const dashboardVideoUrl = isEducationOrganizationPersona
      ? ED_DASHBOARD_OVERVIEW_VIDEO
      : B2B_DASHBOARD_OVERVIEW_VIDEO;

    if (isEducationOrganizationPersona) {
      return (
        <>
          <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
            The dashboard provides administrators, teams, and staff with a
            comprehensive view of district-wide trends and real-time data,
            helping you monitor progress across schools and make informed
            decisions. With up-to-the-minute insights into school operations and
            instructional needs, you can stay ahead of emerging challenges and
            optimize resources for greater impact.
          </div>
          <WizardVideoExplainer
            videoEmbedUrl={dashboardVideoUrl}
          />
        </>
      );
    }

    return (
      <>
        <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
          The dashboard allows you to quickly identify where your customers are facing the most challenges,
          track the popularity of different requests, and understand the financial
          impact of your efforts. With the Dashboard, all stakeholders can easily
          stay aligned and see which customer voices are most relevant to their
          work.
        </div>
        <WizardVideoExplainer
          videoEmbedUrl={dashboardVideoUrl}
        />
      </>
    );
  }
}

WizardDashboardStepHeader.propTypes = {
  isEducationOrganizationPersona: PropTypes.bool.isRequired,
};

export default WizardDashboardStepHeader;
