import React from 'react';

import withRouter from '../../../util/with_router';

import PrimaryDashboardWidgets from './primary_dashboard_widgets';
import RequestsUpdatedRecentlyDashboardWidgets from './requests_updated_recently_dashboard_widgets';
import EducationAdministratorsDashboardWidgets from './education_administrators_dashboard_widgets';

class DashboardWidgetsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {

    const {
      currentLocale,
      currentUser,
      featuresEnabled,
      isCurrentUserAccountAdmin,
      isEducationOrganizationPersona,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      openModal,
      workspaceId,
      dashboardType,
    } = this.props;

    if (dashboardType === 'requests_updated_recently') {
      return (
        <RequestsUpdatedRecentlyDashboardWidgets
          currentLocale={currentLocale}
          workspaceId={workspaceId}
          defaultChannelId={defaultChannelId}
          currentUser={currentUser}
          isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
          key={workspaceId}
          openModal={openModal}
          handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
          isEducationOrganizationPersona={isEducationOrganizationPersona}
        />
      );
    }

    if (isEducationOrganizationPersona) {
      return (
        <EducationAdministratorsDashboardWidgets
          currentLocale={currentLocale}
          workspaceId={workspaceId}
          defaultChannelId={defaultChannelId}
          currentUser={currentUser}
          isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
          featuresEnabled={featuresEnabled}
          key={workspaceId}
          openModal={openModal}
          handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
          isEducationOrganizationPersona={isEducationOrganizationPersona}
        />
      );
    }

    return (
      <PrimaryDashboardWidgets
        currentLocale={currentLocale}
        workspaceId={workspaceId}
        defaultChannelId={defaultChannelId}
        currentUser={currentUser}
        isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
        key={workspaceId}
        openModal={openModal}
        handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
        isEducationOrganizationPersona={isEducationOrganizationPersona}
      />
    );
  }
}

export default withRouter(DashboardWidgetsWrapper);
