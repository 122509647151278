import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchCurrentUser } from '../../../actions/user_actions';
import {
  isB2bOrganizationPersona,
  isEducationOrganizationPersona,
} from '../../../reducers/selectors';

const msp = (state) => {
  const { entities } = state;
  return {
    currentUser: entities.currentUser,
    isB2BPersona: isB2bOrganizationPersona({ entities }),
    isEducationPersona: isEducationOrganizationPersona({ entities }),
  };
};

const mdp = dispatch => {
  return {
    getCurrentUser: ({ organizationId }) => fetchCurrentUser({ organizationId }),
  };
};

export default withRouter(connect(msp, mdp)(Content));
